import React from 'react'
import TechnicianListTable from '../../components/dataTables/technicians/TechnicianListTable'
import { Fade } from 'react-reveal'

const ListTechnicianPage = () => {
    return (
        <Fade>
            <TechnicianListTable/>
        
        </Fade>
    )
}

export default ListTechnicianPage