import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Box, Burger, NavLink } from "@mantine/core";
import { UseGetRole, getUserData } from "../hooks/auth";
import { Link, useLocation } from "react-router-dom";
import { HandleLogoutContext } from "../pages/DashboardLayout";
import { PiMoney, PiUserListFill } from "react-icons/pi";
import { RiCustomerService2Fill } from "react-icons/ri";
import { BsPrinter, BsQrCodeScan } from "react-icons/bs";
import { GrUserWorker } from "react-icons/gr";
import siteLogo from "../assets/images/site-setting/GCS-Logo.png";

import {
  FaLayerGroup,
  FaUserCircle,
  FaUserTie,
  FaUsers,
} from "react-icons/fa";
import {
  BiPurchaseTag,
  BiStoreAlt,
} from "react-icons/bi";
import { MdOutlineLocalFireDepartment } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";

const AdminAside = ({ handleMenuBtn, opened, close }) => {
  const handleLogout = useContext(HandleLogoutContext);

  const userData = getUserData();

  return (
    <div className="dashboard-aside d-flex flex-column justify-content-between align-items-center py-4 px-4 gap-5">
      <span className="aside-toggler d-xl-none position-absolute mt-2">
        <Burger color="#D5AA6D" opened={opened} onClick={handleMenuBtn} />
      </span>
      <Row className="flex-column w-100">
        <Col
          xs={12}
          className="d-flex flex-column align-items-center justify-content-center  px-3 position-relative aside-user-profile-pic"
        >

          <div className="align-items-center w-100 d-flex details ">
            <img src={siteLogo} />
            <h2 className="font-raleway m-0 fw-bolder">
              GCS-ERP
            </h2>
          </div>
        </Col>
        <Col xs={12} className="mt-5">
          <Navigation close={close} />
        </Col>
      </Row>
      <button
        onClick={handleLogout}
        className="primary-btn align-items-center mt-4 text-capitalize d-flex gap-2 ps-2 pe-5 align-self-start"
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="#db9651"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.7 22.0039L10.3 22.0039C13.5 22.0039 15.5 20.0039 15.5 16.8039V12.7539L9.25 12.7539C8.84 12.7539 8.5 12.4139 8.5 12.0039C8.5 11.5939 8.84 11.2539 9.25 11.2539L15.5 11.2539V7.20391C15.5 4.00391 13.5 2.00391 10.3 2.00391L7.71 2.00391C4.51 2.00391 2.51 4.00391 2.51 7.20391L2.51 16.8039C2.5 20.0039 4.5 22.0039 7.7 22.0039Z"
            fill="currentColor"
          />
          <path
            d="M19.9405 12.7539L17.8705 14.8239C17.7205 14.9739 17.6505 15.1639 17.6505 15.3539C17.6505 15.5439 17.7205 15.7439 17.8705 15.8839C18.1605 16.1739 18.6405 16.1739 18.9305 15.8839L22.2805 12.5339C22.5705 12.2439 22.5705 11.7639 22.2805 11.4739L18.9305 8.12388C18.6405 7.83388 18.1605 7.83388 17.8705 8.12388C17.5805 8.41388 17.5805 8.89388 17.8705 9.18388L19.9405 11.2539L15.5005 11.2539V12.7539L19.9405 12.7539Z"
            fill="currentColor"
          />
        </svg>
        Logout
      </button>
    </div>
  );
};

const Navigation = ({ close }) => {
  const role = UseGetRole()
  const location = useLocation();
  const InventoryNav = [
    {
      icon: <FaLayerGroup />,
      label: "Dashboard",
      path: "/dashboard",
      isSubMenu: false,
      Submenu: {

      }
    },
    {
      icon: <PiMoney />,
      label: "Sell",
      path: "/sell/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Sell List",
          path: "/sell/list",
        },
        {
          label: "Sell Requests",
          path: "/sell/requests",
        },
      ]

    },
    {
      icon: <BiPurchaseTag />,
      label: "Puchase",
      path: "/purchase/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Add Puchase",
          path: "/purchase/create",
        },
        {
          label: "Puchase List",
          path: "/purchase/list",
        },
      ]

    },
    {
      icon: <BsPrinter />,
      label: "Printers",
      path: "/printer/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Add Printers",
          path: "/printer/create",
        },
        {
          label: "Printers List",
          path: "/printer/list",
        },
        {
          label: "Models List",
          path: "/models/list",
        },
        {
          label: "Qr Scanner",
          path: "/printer/scanner",
        },
      ]
    },
    {
      icon: <BiStoreAlt />,
      label: "Product",
      path: "/product/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Products List",
          path: "/product/product-list",
        },
        {
          label: "Add  Product",
          path: "/product/product-create",
        },

        {
          label: "Catetory List",
          path: "/product/category-list",
        },
        {
          label: "Add Category",
          path: "/product/category-create",
        },

      ]

    },

    {
      icon: <FaUserCircle />,
      label: "Manage Profile",
      path: "/profile/setting",
      isSubMenu: false,

    },

  ];

  const AdminNav = [
    {
      icon: <FaLayerGroup />,
      label: "Dashboard",
      path: "/dashboard",
      isSubMenu: false,
      Submenu: {

      }
    },
    {
      icon: <PiMoney />,
      label: "Sell",
      path: "/sell/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Sell List",
          path: "/sell/list",
        },
        {
          label: "Sell Requests",
          path: "/sell/requests",
        },
      ]

    },
    {
      icon: <BiPurchaseTag />,
      label: "Puchase",
      path: "/purchase/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Add Puchase",
          path: "/purchase/create",
        },
        {
          label: "Puchase List",
          path: "/purchase/list",
        },
      ]

    },
    {
      icon: <BsPrinter />,
      label: "Printers",
      path: "/printer/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Add Printers",
          path: "/printer/create",
        },
        {
          label: "Printers List",
          path: "/printer/list",
        },

        // {
        //   label: "Add Models",
        //   path: "/models/create",
        // },
        {
          label: "Models List",
          path: "/models/list",
        },
        {
          label: "Qr Scanner",
          path: "/printer/scanner",
        },
      ]

    },
    {
      icon: <BiStoreAlt />,
      label: "Product",
      path: "/product/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Products List",
          path: "/product/product-list",
        },
        {
          label: "Add  Product",
          path: "/product/product-create",
        },
        {
          label: "Barcode Print",
          path: "/product/barcode",
        },
        {
          label: "Catetory List",
          path: "/product/category-list",
        },
        {
          label: "Add Category",
          path: "/product/category-create",
        },
        {
          label: "Stock Alert",
          path: "/product/stock-alert",
        },
      ]

    },
    {
      icon: <FaUsers />,
      label: "Admin",
      path: "/admin/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Create Admins",
          path: "/admin/create",
        },
        {
          label: "Admins List",
          path: "/admin/list",
        },
      ]

    },
    {
      icon: <RiCustomerService2Fill />,
      label: "Customer Registration",
      path: "/customer/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Manage Customers",
          path: "/customer/list",
        },
        {
          label: "Manage Departments",
          path: "/department/list",
        },
        {
          label: "Manage Locations",
          path: "/location/list",
        },
      ]

    },


    {
      icon: <FaUserTie />,
      label: "Requester",
      path: "/requester/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Add Requester",
          path: "/requester/create",
        },
        {
          label: "Requester List",
          path: "/requester/list",
        },
      ]

    },
    {
      icon: <GrUserWorker />,
      label: "Technician",
      path: "/technician/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Add Technician",
          path: "/technician/create",
        },
        {
          label: "Technician List",
          path: "/technician/list",
        },

      ]

    },
    {
      icon: <PiUserListFill />,
      label: "Supervisor",
      path: "/supervisor/list",
      isSubMenu: true,
      Submenu: [
        {
          label: "Add Supervisor",
          path: "/supervisor/create",
        },
        {
          label: "Supervisor List",
          path: "/supervisor/list",
        },

      ]

    },

    {
      icon: <FaUserCircle />,
      label: "Complaints",
      path: "/complaints/list",
      isSubMenu: false,

    },
    {
      icon: <FaUserCircle />,
      label: "Manage Profile",
      path: "/profile/setting",
      isSubMenu: false,

    },

  ];
  let activePaths = [];
  const data = role == 'admin' ? AdminNav : InventoryNav;
  const items = data.map((item, index) =>

  (
    <div key={index}>
      {item.isSubMenu == true ? (
        <NavLink
          key={index}
          label={item.label}
          description={item.description}
          rightSection={item.rightSection}
          icon={item.icon}
          childrenOffset={28}

        >
          {item.Submenu.map((subItem, i) => (


            <Link
              key={i}
              to={subItem.path}
              onClick={close}
              className="text-decoration-none"
            >
              <NavLink
                label={subItem.label}
                active={location.pathname === subItem.path}
              />
            </Link>
          ))}

        </NavLink>
      ) : (
        <Link
          to={item.path}
          onClick={close}
          // key={index}
          className="text-decoration-none"
        >
          <NavLink
            active={location.pathname === item.path}
            label={item.label}
            description={item.description}
            rightSection={item.rightSection}
            icon={item.icon}
          />
        </Link>
      )}
    </div>
  )
  );

  return (
    <Box w="100%">
      {items}

    </Box>
  );
};

export default AdminAside;

