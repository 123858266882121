import { DataTable } from "mantine-datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Text, TextInput } from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchProduct } from "../../../redux/actions/productAction";
import { Button } from '@mantine/core';
import { FetchPrinterByRegion, fetchPrinterModel, storePrinterModel } from "../../../redux/actions/printerAction";
import { IconSearch } from "@tabler/icons-react";
import { BsQrCode } from "react-icons/bs";
import { fetchRegion } from "../../../redux/actions/regionAction";
const PrinterListTable = ({ PAGE_SIZE = 30 }) => {
    const [data, setProductData] = useState([]);
    const [dataOfTable, setDataOfTable] = useState(data || []);
    const [page, setPage] = useState(1);
    const { loading, PrinterByRegion } = useSelector((state) => state?.printers);
    const { regionList } = useSelector((state) => state?.region);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [regions, setRegions] = useState([])
    const [regionValue, setRegionValue] = useState(1)
    const [records, setRecords] = useState(dataOfTable?.slice(0, PAGE_SIZE) || []);

    const [query, setQuery] = useState('');

    useEffect(() => {
        const names = regionList.map((item) => ({ value: item.id, label: item.name }));
        setRegions(names);
    }, [regionList]);
    
    useEffect(() => {
        setDataOfTable(
            data?.filter(({ model }) => {
                return model?.toLowerCase()?.includes(query?.trim()?.toLowerCase());
            })
        );
    }, [query]);



    useEffect(() => {
        setDataOfTable(data);
    }, [data]);



    useEffect(() => {
        dispatch(FetchPrinterByRegion(regionValue));
    }, [regionValue]);

    useEffect(() => {
        dispatch(fetchRegion());
    }, []);

    useEffect(() => {
        setProductData(PrinterByRegion)
    }, [PrinterByRegion]);

    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(dataOfTable?.slice(from, to));
    }, [page, dataOfTable]);

    const handleDelete = async (id) => {
        // await dispatch(deleteProduct(id)).then((res) => {
        //     if (dataOfTable?.length !== 1 && records.length === 1) {
        //         setPage(page - 1);
        //     }
        // });
    };

    const printQRCode = (qrCodeUrl, model, brand, serial_number) => {
        const printWindow = window.open('', '', 'width=200,height=200');
        printWindow.document.open();
        printWindow.document.write('<html><body>');
        printWindow.document.write(

            `
            <div style="width:200px;height:200px">
            <img src="${qrCodeUrl}" alt="QR Code" width="200" height="200" />
            <p style="margin-top:10px">  ${brand} -  ${model} -   ${serial_number}</p>
            
            </div>
            `
        );
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    const product_columns = [
        {
            accessor: "SNO",
            title: <Text>#</Text>,
            width: "auto",
            render: (record, i) => <Text>{i + 1}</Text>,
        },
        {
            accessor: "qrcodes",
            width: "auto",
            title: "QR Codes",
            render: (record, i) => (
                <Text className=" text-center" onClick={() => printQRCode(record.qrcodes, record.model, record.brand, record.serial_number)}><BsQrCode className="fa-3x text-center" /></Text>
            ),
        },
        {
            accessor: "brand",
            width: "auto",
            title: "Brand",

        },

        {
            accessor: "model",
            width: "auto",
            title: 'Model',
            filter: (
                <TextInput
                    label="Model"
                    placeholder="Search Model..."
                    icon={<IconSearch size={16} />}
                    value={query}
                    onChange={(e) => setQuery(e.currentTarget.value)}
                />
            ),

        },
        {
            accessor: "name",
            width: "auto",
            title: "Printer Name",
            render: (record, i) => (
                <Link to={`/printer/view/${record.id}`}>
                    {record.name}
                </Link>
            ),

        },
        {
            accessor: "serial_number",
            width: "auto",
            title: "Serial Name",

        },
        {
            accessor: "customer",
            width: "auto",
            title: "Customer",

        },
        {
            accessor: "location",
            width: "auto",
            title: "Location",

        },
        {
            accessor: "department",
            width: "auto",
            title: "Department",

        },
        {
            accessor: "counter",
            width: "auto",
            title: "Counters",

        },
        {
            accessor: "actions",
            width: "auto",
            textAlignment: "right",
            title: <Text>Actions</Text>,
            render: (record) => (
                <div>

                </div>
            ),
        },
    ];


    return (
        <div>
            <div className="d-flex flex-wrap mb-3 justify-content-between align-items-end">
                <h3 className="m-0" onClick={() => dispatch(FetchPrinterByRegion(regionValue))}>Printer List</h3>
                <div className="d-flex  flex-wrap gap-2 justify-content-between align-items-end">
                    <Button

                        size="xs"
                        className="text-uppercase mt-2"
                        variant="gradient"
                        gradient={{ from: 'lime', to: 'teal', deg: 90 }}
                        onClick={() => navigate('/printer/create')}>
                        Add Printer
                    </Button>
                    <Select
                        size="xs"
                        defaultValue={regionValue}
                        value={regionValue}
                        label="Region"
                        placeholder="Pick Region"
                        onChange={(e) => setRegionValue(e)}
                        data={regions}
                         
                        clearable
                    />
                </div>
            </div>
            <DataTable
                className="data-table-with-actions"
                withBorder
                records={records}
                withColumnBorders
                striped
                highlightOnHover
                verticalSpacing={10}
                columns={
                    product_columns
                }
                totalRecords={dataOfTable?.length}
                recordsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={(p) => setPage(p)}
                fetching={loading}
                minHeight={400}
                loaderVariant="dots"
                noRecordsText="No records found"
                paginationText={({ from, to, totalRecords }) =>
                    `Records ${from} - ${to} of ${totalRecords}`
                }
                paginationSize="md"
            />
        </div>
    );
}

export default PrinterListTable