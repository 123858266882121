import { Button, FileInput, Select, TextInput } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Fade, Zoom } from 'react-reveal'
import { storeBulkLocations } from '../../redux/actions/locationAction' 
import { fetchRegion } from '../../redux/actions/regionAction'
import { useSelector } from 'react-redux'
import { errorMessage } from '../../globalFunctions'

const AddLocationsPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [region, setRegion] = useState('')
    const [location, setLocation] = useState('')
    const dispatch = useDispatch()

    const { regionList } = useSelector((state) => state?.region)
    const [regionData, setRegionsData] = useState([])
    useEffect(() => {
        dispatch(fetchRegion())
    }, [])

    useEffect(() => {
        const names = regionList.map((item) => ({ value: item.id, label: item.name }));
        setRegionsData(names);
    }, [regionList])



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            if (region == null || region == '' || location == null || location == undefined) {
                setIsLoading(false)
                return errorMessage('Please fill all the fields')
            }
            const formData = new FormData()
            formData.append('name', location)
            formData.append('region', region)
            dispatch(storeBulkLocations(formData, 'store')).then((res) => {
                if (res.status == 'success') {
                    e.target.reset();
                    setLocation(null);
                    setRegion(null);
                }
            })
        } catch (error) {

        }
        setIsLoading(false)
    }


    return (
        <Fade>
            <h3>Add Location</h3>
            <form onSubmit={handleSubmit}>
                <TextInput
                    value={location}
                    name='name'
                    onChange={(e) => setLocation(e.target.value)}
                    label="Location Name"
                    className='mt-3'
                    placeholder="Location Name"
                    requried
                    withAsterisk

                />

                <Select
                    onChange={(e) => setRegion(e)}
                    label="Regions"
                    name='regions'
                    value={region}
                    placeholder="Please Select"
                    data={regionData}
                    searchable
                    className='mt-3 w-100  required-input'
                />

                <Button
                    className='mt-5'
                    variant="gradient"
                    type='submit'
                    disabled={isLoading}
                    gradient={{ from: 'teal', to: 'green', deg: 172 }}
                >
                    Add Product
                </Button>
            </form>


        </Fade>
    )
}

export default AddLocationsPage