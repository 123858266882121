export const authConstants = {
  DOMAIN_VERIFY_REQUEST: "DOMAIN_VERIFY_REQUEST",
  DOMAIN_VERIFY_SUCCESS: "DOMAIN_VERIFY_SUCCESS",
  DOMAIN_VERIFY_FAILURE: "DOMAIN_VERIFY_FAILURE",
 
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  REGISTER_WITH_REQUEST: "REGISTER_WITH_REQUEST",
  REGISTER_WITH_SUCCESS: "REGISTER_WITH_SUCCESS",
  REGISTER_WITH_FAILURE: "REGISTER_WITH_FAILURE",

  LOGIN_WITH_REQUEST: "LOGIN_WITH_REQUEST",
  LOGIN_WITH_SUCCESS: "LOGIN_WITH_SUCCESS",
  LOGIN_WITH_FAILURE: "LOGIN_WITH_FAILURE",

  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILURE: "VERIFY_EMAIL_FAILURE",

  REQUEST_VERIFICATION_TOKEN_REQUEST: "REQUEST_VERIFICATION_TOKEN_REQUEST",
  REQUEST_VERIFICATION_TOKEN_SUCCESS: "REQUEST_VERIFICATION_TOKEN_SUCCESS",
  REQUEST_VERIFICATION_TOKEN_FAILURE: "REQUEST_VERIFICATION_TOKEN_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  RESEND_TOKEN_REQUEST: "RESEND_TOKEN_REQUEST",
  RESEND_TOKEN_SUCCESS: "RESEND_TOKEN_SUCCESS",
  RESEND_TOKEN_FAILURE: "RESEND_TOKEN_FAILURE",

  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};
