import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { counterLogConstants } from "../constants/counterLogsContants";

export const fetchCounterByPrinter = (id, date) => async (dispatch) => {

    dispatch({ type: counterLogConstants.FETCH_COUNTER_BY_PRINTER_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get(`/admin/counter-log/list/${id}/${date}`);
        if (res?.data?.status == "success") {
            dispatch({
                type: counterLogConstants.FETCH_COUNTER_BY_PRINTER_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: counterLogConstants.FETCH_COUNTER_BY_PRINTER_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: counterLogConstants.FETCH_COUNTER_BY_PRINTER_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};  