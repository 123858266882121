
export const unitConstants = {
    FETCH_UNIT_REQUEST: "FETCH_UNIT_REQUEST",
    FETCH_UNIT_SUCCESS: "FETCH_UNIT_SUCCESS",
    FETCH_UNIT_FAILURE: "FETCH_UNIT_FAILURE",

    STORE_UNIT_REQUEST: "STORE_UNIT_REQUEST",
    STORE_UNIT_SUCCESS: "STORE_UNIT_SUCCESS",
    STORE_UNIT_FAILURE: "STORE_UNIT_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
