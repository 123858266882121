import { DataTable } from "mantine-datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchProduct } from "../../../redux/actions/productAction";
import { Button } from '@mantine/core';
const ProductListTable = ({ PAGE_SIZE = 10 }) => {
    const [data, setProductData] = useState([]);
    const [dataOfTable, setDataOfTable] = useState(data || []);
    const [page, setPage] = useState(1);

    const { loading, products } = useSelector((state) => state?.products);

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState(null);
    const [records, setRecords] = useState(dataOfTable?.slice(0, PAGE_SIZE) || []);
    const [country, setCountry] = useState("");
    const [debouncedQuery] = useDebouncedValue(country, 200);

    useEffect(() => {
        setDataOfTable(
            data?.filter(({ country }) => {
                if (debouncedQuery !== "") {
                    if (
                        country?.name
                            ?.toLowerCase()
                            ?.includes(debouncedQuery?.trim()?.toLowerCase())
                    ) {
                        return country;
                    }
                } else {
                    return country;
                }
            })
        );
    }, [data, debouncedQuery]);
    useEffect(() => {
        setDataOfTable(data);
    }, [data]);



    useEffect(() => {
        dispatch(fetchProduct());
    }, []);

    useEffect(() => {
        setProductData(products)
        console.log(products);
    }, [products]);

    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(dataOfTable?.slice(from, to));
    }, [page, dataOfTable]);

    const handleDelete = async (id) => {
        // await dispatch(deleteProduct(id)).then((res) => {
        //     if (dataOfTable?.length !== 1 && records.length === 1) {
        //         setPage(page - 1);
        //     }
        // });
    };



    const defualt_columns = [
        {
            accessor: "SNO",
            title: <Text>#</Text>,
            width: "auto",
            render: (record, i) => <Text>{i + 1}</Text>,
        },
        { accessor: "name", width: "auto" },
        {
            accessor: "status",
            width: "auto",
            render: (record) => (
                <Text>{record?.status === 1 ? "Active" : "Inactive"}</Text>
            ),
        },
        {
            accessor: "actions",
            width: "auto",
            textAlignment: "right",
            title: <Text>Actions</Text>,
            render: (record) => { '' }

        },
    ];

    const product_columns = [
        {
            accessor: "SNO",
            title: <Text>#</Text>,
            width: "auto",
            render: (record, i) => <Text>{i + 1}</Text>,
        },
        {
            accessor: "title",
            width: "auto",
            title: "Title",
         
        },
        {
            accessor: "model_number",
            width: "auto",
            title: 'Model Number'
        },
        {
            accessor: "serial_number",
            width: "auto",
            title: 'Serial Number'
        },
        {
            accessor: "category",
            width: "auto",
            title: 'Category',
            render: (record) => (<Text>{record.category?.name}</Text>)

        },
        {
            accessor: "unit",
            width: "auto",
            title: 'Unit',
            render: (record) => (<Text>{record.unit?.name}</Text>),
        },
        {
            accessor: "brand",
            width: "auto",
            title: 'Brand',
            render: (record) => (<Text>{record?.brand?.name}</Text>),
        },
        {
            accessor: "actions",
            width: "auto",
            textAlignment: "right",
            title: <Text>Actions</Text>,
            render: (record) => (
                <div>

                </div>
            ),
        },
    ];


    return (
        <div>
            <div className="d-flex mb-3 justify-content-between align-items-end">
                <h3 className="m-0" onClick={() => dispatch(fetchProduct())}>Product List</h3>
                <Button
                    className="text-uppercase"
                    variant="gradient"
                    gradient={{ from: 'lime', to: 'teal', deg: 90 }}
                    onClick={() => navigate('/product/product-create')}>
                    Add New Product
                </Button>
            </div>
            <DataTable
                className="data-table-with-actions"
                withBorder
                records={records}
                withColumnBorders
                striped
                highlightOnHover
                verticalSpacing={10}
                columns={
                    product_columns
                }
                totalRecords={dataOfTable?.length}
                recordsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={(p) => setPage(p)}
                fetching={loading}
                minHeight={400}
                loaderVariant="dots"
                noRecordsText="No records found"
                paginationText={({ from, to, totalRecords }) =>
                    `Records ${from} - ${to} of ${totalRecords}`
                }
                paginationSize="md"
            />
        </div>
    );
}

export default ProductListTable