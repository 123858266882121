import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { requesterConstants } from "../constants/requesterConstants";

export const storeRequester = (value) => async (dispatch) => {

    dispatch({ type: requesterConstants.STORE_REQUESTER_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/requester/store", value);
        if (res?.data?.status == "success") {

            dispatch({
                type: requesterConstants.STORE_REQUESTER_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(JSON.stringify(res?.data?.message))
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: requesterConstants.STORE_REQUESTER_FAILURE,
            });
            errorMessage(JSON.stringify(res?.data?.message))
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: requesterConstants.STORE_REQUESTER_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(JSON.stringify(error.response.data.message));
    }
};

export const fetchRequester = () => async (dispatch) => {

    dispatch({ type: requesterConstants.FETCH_REQUESTER_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/requester/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: requesterConstants.FETCH_REQUESTER_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: requesterConstants.FETCH_REQUESTER_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: requesterConstants.FETCH_REQUESTER_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};

export const destroyRequester = (id) => async (dispatch) => {

    dispatch({ type: requesterConstants.DESTROY_REQUESTER_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get(`/admin/requester/destroy/${id}`);

        if (res?.data?.status == "success") {
            dispatch({
                type: requesterConstants.DESTROY_REQUESTER_SUCCESS,
                payload: id,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: requesterConstants.DESTROY_REQUESTER_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: requesterConstants.DESTROY_REQUESTER_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};  