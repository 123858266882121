import { technicianConstants } from "../constants/technicianConstants";

export const technicianReducer = (
    state = {
        loading: false,
        error: null,
        technicianList: [],
    },
    action
) => {
    switch (action.type) {
        case technicianConstants.FETCH_TECHNICIAN_REQUEST:
        case technicianConstants.STORE_TECHNICIAN_REQUEST:
        case technicianConstants.DESTROY_TECHNICIAN_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case technicianConstants.FETCH_TECHNICIAN_SUCCESS:
            return {
                ...state,
                loading: false,
                technicianList: action.payload,
            };

        case technicianConstants.DESTROY_TECHNICIAN_SUCCESS:
            const updatedRequesterList = state.technicianList.filter((item) => item.id !== action.payload);
            return {
                ...state,
                loading: false,
                technicianList: updatedRequesterList,
            };
        case technicianConstants.STORE_TECHNICIAN_SUCCESS:
            return {
                ...state,
                loading: false,

            };


        case technicianConstants.DESTROY_TECHNICIAN_FAILURE:
        case technicianConstants.FETCH_TECHNICIAN_FAILURE:
        case technicianConstants.STORE_TECHNICIAN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}