
export const customerConstants = {
    FETCH_CUSTOMER_REQUEST: "FETCH_CUSTOMER_REQUEST",
    FETCH_CUSTOMER_SUCCESS: "FETCH_CUSTOMER_SUCCESS",
    FETCH_CUSTOMER_FAILURE: "FETCH_CUSTOMER_FAILURE",

    STORE_CUSTOMER_REQUEST: "STORE_CUSTOMER_REQUEST",
    STORE_CUSTOMER_SUCCESS: "STORE_CUSTOMER_SUCCESS",
    STORE_CUSTOMER_FAILURE: "STORE_CUSTOMER_FAILURE",

    DESTROY_CUSTOMER_REQUEST: "DESTROY_CUSTOMER_REQUEST",
    DESTROY_CUSTOMER_SUCCESS: "DESTROY_CUSTOMER_SUCCESS",
    DESTROY_CUSTOMER_FAILURE: "DESTROY_CUSTOMER_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
