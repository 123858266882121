import React, { useEffect, useState } from 'react'
import { Button, Modal, TextInput, Select } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../../globalFunctions';
import { storeBulkLocations } from '../../../redux/actions/locationAction';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchRegion } from '../../../redux/actions/regionAction';

const LocationsModal = ({ setLocationData, setModalPopup, modalPopup }) => {


    const [Name, setName] = useState('')
    const [region, setRegion] = useState('')
    const dispatch = useDispatch();
    const [regionData, setRegionsData] = useState([])
    const navigate = useNavigate()
    const { loading, regionList } = useSelector((state) => state?.region)
    const [isLoading, setLoading] = useState(loading);
    useEffect(() => {
        dispatch(fetchRegion())
    }, [])

    useEffect(() => {
        const names = regionList.map((item) => ({ value: item.id, label: item.name }));
        setRegionsData(names);
    }, [regionList])



    const HandleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (Name.trim() == '') {
                return errorMessage('Enter the location name');
            }
            if (region == '') {
                return errorMessage('Select location region!');
            }
            setLoading(true) 
            const formdata = new FormData()
            formdata.append('name', Name.trim())
            formdata.append('region', region)
            await dispatch(storeBulkLocations(formdata, 'store')).then((res) => {

                if (res.status == 'success') {
                    setLocationData((prevValue) => [{ ...res.data }, ...prevValue]);
                    setName('')
                    setModalPopup(false);
                }
            })
        } catch (error) {

        }
        setLoading(false)
    }
    return (
        <Modal opened={modalPopup} onClose={() => setModalPopup(false)} title="Add Location" centered>

            <form onSubmit={HandleSubmit}>
                <TextInput
                    value={Name}
                    name='name'
                    onChange={(e) => setName(e.target.value)}
                    label="Location Name"
                    className='mt-3'
                    placeholder="Location Name" 
                    withAsterisk

                />
                <Select
                    onChange={(e) => setRegion(e)}
                    label="Regions"
                    name='regions'
                    value={region}
                    placeholder="Please Select"
                    data={regionData}
                    withAsterisk
                    className='mt-3 w-100 '
                />

                <Button
                    className='mt-5'
                    variant="gradient"
                    type='submit'
                    disabled={isLoading}
                    gradient={{ from: 'teal', to: 'green', deg: 172 }}
                >
                    Add Location
                </Button>
            </form>
        </Modal>
    )
}

export default LocationsModal