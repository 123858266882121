import { departmentConstants } from "../constants/departmentConstants";

export const departmentReducer = (
    state = {
        loading: false,
        error: null,
        departmentList: [],
    },
    action
) => {
    switch (action.type) {
        case departmentConstants.FETCH_DEPARTMENT_REQUEST:
        case departmentConstants.STORE_DEPARTMENT_REQUEST:
        case departmentConstants.DESTROY_DEPARTMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case departmentConstants.FETCH_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                departmentList: action.payload,
            };
        case departmentConstants.STORE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case departmentConstants.DESTROY_DEPARTMENT_SUCCESS:
            const data = state.departmentList.filter((item) => item.id != action.payload);
            return {
                ...state,
                loading: false,
                departmentList: data

            };


        case departmentConstants.FETCH_DEPARTMENT_FAILURE:
        case departmentConstants.STORE_DEPARTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}