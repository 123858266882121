import { Button, FileInput } from '@mantine/core'
import React, { useState } from 'react'
import { Fade, Zoom } from 'react-reveal'
import { storeBulkDepartment } from '../../redux/actions/departmentAction'
import { useDispatch } from 'react-redux'
import { errorMessage } from '../../globalFunctions'
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom'

const StoreBulkDepartmentPage = () => {
    const [file, setFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [csvData, setCsvData] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            if (file == null || file == undefined) {
                setIsLoading(false)
                return errorMessage('Please fill all the fields')
            }
            const formData = new FormData()
            formData.append('csv_file', file)
            await dispatch(storeBulkDepartment(formData, 'bulk')).then((res) => {
                if (res.status == 'success') {
                    e.target.reset();
                    setFile(null);
                    setCsvData([])
                }
            })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }

    }
    const handleFileUpload = (e) => {
        const file = e;
        setCsvData(null)
        setFile(file)
        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    setCsvData(result.data);
                },
                header: false,
            });
        }
    };

    return (
        <Fade>
            <div className="d-flex mb-3 justify-content-between align-items-end">
                <h3>Add Department</h3>
                <Button
                    className="text-uppercase"
                    variant="gradient"
                    size='sm'
                    gradient={{ from: 'rgba(5, 37, 92, 1)', to: 'rgba(0, 170, 255, 1)', deg: 172 }}
                    onClick={() => navigate('/department/list')}>
                    Departments List
                </Button>
            </div>
            <form onSubmit={handleSubmit}>
                <FileInput
                    onChange={(e) => handleFileUpload(e)}
                    value={file}
                    variant="filled"
                    label="Upload CSV"
                    description="Please upload a CSV file containing only department names, without any additional fields."
                    placeholder="Upload CSV"
                    accept=".csv"
                    withAsterisk
                    className='mt-3'
                    disabled={isLoading}
                />

                <Button
                    className='mt-5'
                    variant="gradient"
                    type='submit'
                    disabled={isLoading}
                    gradient={{ from: 'teal', to: 'green', deg: 172 }}
                >
                    Add Department
                </Button>
            </form>
            {csvData?.length > 0 ? (
                <div className=' csv-data location-csv'>
                    {csvData.map((res, i) => (
                        <Zoom>
                            <span className='csv-data' key={i}> <i>{i + 1}.</i> {res}</span>
                        </Zoom>
                    ))}

                </div>
            ) : ''}
        </Fade>
    )
}

export default StoreBulkDepartmentPage