import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Html5QrcodeScanner } from "html5-qrcode"
import { useNavigate } from 'react-router-dom';

const QrScannerPage = () => {
    const [scanResult, setScanResult] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            fps: 5
        });

        scanner.render(success, error);
        function success(result) {
            scanner.clear();
            setScanResult(result)
            navigate(`/printer/view/${result}`);
        }
        function error(err) {
            console.warn(err);
        }
    }, []);


    return (
        <div className='qrScanner'>

            <div id="reader"></div>

        </div>
    )
}

export default QrScannerPage