import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { unitConstants } from "../constants/unitConstants";

export const storeUnits = (value) => async (dispatch) => {

    dispatch({ type: unitConstants.STORE_UNIT_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/units/store", value);
        if (res?.data?.status == "success") {

            dispatch({
                type: unitConstants.STORE_UNIT_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(res?.data?.message)
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: unitConstants.STORE_UNIT_FAILURE,
            });
            errorMessage(res?.data?.message)
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: unitConstants.STORE_UNIT_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};
export const fetchUnits = () => async (dispatch) => {

    dispatch({ type: unitConstants.FETCH_UNIT_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/units/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: unitConstants.FETCH_UNIT_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: unitConstants.FETCH_UNIT_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: unitConstants.FETCH_UNIT_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};  