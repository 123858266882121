
export const productConstants = {
    FETCH_PRODUCT_REQUEST: "FETCH_PRODUCT_REQUEST",
    FETCH_PRODUCT_SUCCESS: "FETCH_PRODUCT_SUCCESS",
    FETCH_PRODUCT_FAILURE: "FETCH_PRODUCT_FAILURE",

    STORE_PRODUCT_REQUEST: "STORE_PRODUCT_REQUEST",
    STORE_PRODUCT_SUCCESS: "STORE_PRODUCT_SUCCESS",
    STORE_PRODUCT_FAILURE: "STORE_PRODUCT_FAILURE",

    SEARCH_PRODUCT_REQUEST: "SEARCH_PRODUCT_REQUEST",
    SEARCH_PRODUCT_SUCCESS: "SEARCH_PRODUCT_SUCCESS",
    SEARCH_PRODUCT_FAILURE: "SEARCH_PRODUCT_FAILURE",
 

    CLEAR_ERRORS: "CLEAR_ERRORS",
};
