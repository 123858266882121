import custAxios, { attachToken, attachTokenWithFormAxios, formAxios } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { customerConstants } from "../constants/customerContants";

export const storeBulkCustomers = (data, EndPoint) => async (dispatch) => {

    dispatch({ type: customerConstants.STORE_CUSTOMER_REQUEST });
    try {
        attachTokenWithFormAxios();

        const res = await formAxios.post(`/admin/customer/${EndPoint}`, data);
        if (res?.data?.status == "success") {
            dispatch({
                type: customerConstants.STORE_CUSTOMER_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(res?.data?.message)
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: customerConstants.STORE_CUSTOMER_FAILURE,
            });
            errorMessage(res?.data?.message)
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: customerConstants.STORE_CUSTOMER_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};
export const destroyCustomer = (id) => async (dispatch) => {

    dispatch({ type: customerConstants.DESTROY_CUSTOMER_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get(`/admin/customer/destroy/${id}`);

        if (res?.data?.status == "success") {
            dispatch({
                type: customerConstants.DESTROY_CUSTOMER_SUCCESS,
                payload: id,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: customerConstants.DESTROY_CUSTOMER_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: customerConstants.DESTROY_CUSTOMER_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};

export const fetchCustomer = () => async (dispatch) => {

    dispatch({ type: customerConstants.FETCH_CUSTOMER_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/customer/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: customerConstants.FETCH_CUSTOMER_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: customerConstants.FETCH_CUSTOMER_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: customerConstants.FETCH_CUSTOMER_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};

