import React from 'react'
import CustomerListTable from '../../components/dataTables/customers/CustomerListTable'

const ListCustomerPage = () => {
    return (
        <div>
            <CustomerListTable />
        </div>
    )
}

export default ListCustomerPage