import React from 'react'
import { Fade } from 'react-reveal'
import RequesterListTable from '../../components/dataTables/requester/RequesterListTable';



const ListRequesterPage = () => {
    return (

        <Fade>
            <RequesterListTable />


        </Fade>
    )
}
export default ListRequesterPage;