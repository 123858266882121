import { Badge, Button, Popover, Text, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { destroyRequester, fetchRequester } from '../../../redux/actions/requeterAction';
import { DataTable } from 'mantine-datatable';

const RequesterListTable = ({ PAGE_SIZE = 30 }) => {
    const [data, setProductData] = useState([]);
    const [dataOfTable, setDataOfTable] = useState(data || []);
    const [page, setPage] = useState(1);
    const { loading, requesterList } = useSelector((state) => state?.requester);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [records, setRecords] = useState(dataOfTable?.slice(0, PAGE_SIZE) || []);
    const [query, setQuery] = useState('');

    useEffect(() => {
        setDataOfTable(
            data?.filter(({ email }) => {
                return email?.toLowerCase()?.includes(query?.trim()?.toLowerCase());
            })
        );
    }, [query]);




    useEffect(() => {
        setDataOfTable(data);
    }, [data]);



    useEffect(() => {
        dispatch(fetchRequester());
    }, []);

    useEffect(() => {
        setProductData(requesterList)
    }, [requesterList]);

    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(dataOfTable?.slice(from, to));
    }, [page, dataOfTable]);

    const handleDelete = async (id) => {
        // await dispatch(destroyRequester(id)).then((res) => {
        //     if (dataOfTable?.length !== 1 && records.length === 1) {
        //         setPage(page - 1);
        //     }
        // });
    };
    const product_columns = [
        {
            accessor: "SNO",
            title: <Text>#</Text>,
            width: "auto",
            render: (record, i) => <Text>{i + 1}</Text>,
        },
        {
            accessor: "id",
            width: "auto",
            title: "Id",
        },
        {
            accessor: "first_name",
            width: "auto",
            title: "Fullname",
            render: (records) => (<Text>{records?.first_name} {records?.last_name}</Text>),

        },
        {
            accessor: "email",
            width: "auto",
            title: "Email",

            filter: (
                <TextInput
                    label="Email"
                    placeholder="Search emails..."
                    icon={<IconSearch size={16} />}
                    value={query}
                    onChange={(e) => setQuery(e.currentTarget.value)}
                />
            ),
        },
        {
            accessor: "id",
            width: "auto",
            title: "Region",
            render: (records) => (<Text>{records?.region?.name} </Text>),
        },
        {
            accessor: "id",
            width: "auto",
            title: "Customer",
            render: (records) => (<Text>{records?.customer?.name} </Text>),
        },
        {
            accessor: "id",
            width: "auto",
            title: "Location",
            render: (records) => <Text>{records?.location?.name} </Text>,
        },
        {
            accessor: "status",
            width: "auto",
            title: "status",
            render: (records) => <Badge variant='filled' color={records.status == 'Active' ? 'blue' : 'red'}>{records.status}</Badge>,

        },

    ];




    return (
        <div>
            <div className="d-flex mb-3 justify-content-between align-items-end">
                <h3 className="m-0" onClick={() => dispatch(fetchRequester())}>Requesters List</h3>
                <div className="d-flex gap-3">
                    <Button
                        className="text-uppercase"
                        variant="gradient"
                        gradient={{ from: 'lime', to: 'teal', deg: 90 }}
                        onClick={() => navigate('/requester/create')}>
                        Add New Requester
                    </Button>
                </div>
            </div>
            <DataTable
                className="data-table-with-actions"
                withBorder
                records={records}
                withColumnBorders
                striped
                highlightOnHover
                verticalSpacing={10}
                columns={
                    product_columns
                }
                totalRecords={dataOfTable?.length}
                recordsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={(p) => setPage(p)}
                fetching={loading}
                minHeight={400}
                loaderVariant="dots"
                noRecordsText="No records found"
                paginationText={({ from, to, totalRecords }) =>
                    `Records ${from} - ${to} of ${totalRecords}`
                }
                paginationSize="md"
            />
        </div>
    )
}

export default RequesterListTable