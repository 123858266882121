import { Badge, Button, Modal, Popover, Select, Text, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { destroyRequester, fetchRequester } from '../../../redux/actions/requeterAction';
import { DataTable } from 'mantine-datatable';
import { fetchComplaintsById } from '../../../redux/actions/complaintsActions';
import { MonthPickerInput } from '@mantine/dates';
import { fetchRegion } from '../../../redux/actions/regionAction';

const ComplaintsListTable = ({ PAGE_SIZE = 30 }) => {
    const [data, setProductData] = useState([]);
    const [dataOfTable, setDataOfTable] = useState(data || []);
    const [page, setPage] = useState(1);
    const { loading, complaintsList } = useSelector((state) => state?.complaints);
    const { regionList } = useSelector((state) => state?.region);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState(new Date());
    const [regionValue, setRegionValue] = useState(2)
    const [regions, setRegions] = useState([])

    const [records, setRecords] = useState(dataOfTable?.slice(0, PAGE_SIZE) || []);
    const [query, setQuery] = useState('');
    const [statusQuery, setStatusQuery] = useState('');

    useEffect(() => {
        const names = regionList.map((item) => ({ value: item.id, label: item.name }));
        setRegions(names);
    }, [regionList]);

    useEffect(() => {
        setDataOfTable(
            data?.filter(({ customer, status }) => {
                const customerMatch = customer?.toLowerCase()?.includes(query?.trim()?.toLowerCase());
                const statusMatch = status?.toLowerCase()?.includes(statusQuery?.trim()?.toLowerCase());
                return customerMatch && statusMatch;
            })
        );
    }, [query, statusQuery]);


    useEffect(() => {
        dispatch(fetchRegion());
    }, []);


    useEffect(() => {
        setDataOfTable(data);
    }, [data]);



    useEffect(() => {
        dispatch(fetchComplaintsById(regionValue, formatDateToMMYYYY(value)));
    }, [regionValue, value]);

    useEffect(() => {
        setProductData(complaintsList)
    }, [complaintsList]);

    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(dataOfTable?.slice(from, to));
    }, [page, dataOfTable]);

    const [modalOpened, setModalOpened] = useState(false);
    const [modalFile, setModalFile] = useState('');

    const modalHandle = (img) => {
        setModalOpened(true);
        setModalFile(img);
    }

    const product_columns = [

        {
            accessor: "id",
            width: "auto",
            title: "Token",
        },
        {
            accessor: "customer",
            width: "auto",
            title: "CUSTOMERS",
            filter: (
                <TextInput
                    label="Customer"
                    placeholder="Customer emails..."
                    icon={<IconSearch size={16} />}
                    value={query}
                    clearable
                    onChange={(e) => setQuery(e.currentTarget.value)}
                />
            ),

        },
        {
            accessor: "location",
            width: "auto",
            title: "LOCATIONS",
        },
        {
            accessor: "problem",
            width: "auto",
            title: "PROBLEMS",

        },
        {
            accessor: "printer",
            width: "auto",
            title: "PRINTERS",
            render: (records) => (<Link to={`/printer/view/${records?.printer?.id}`}>{records?.printer?.brand} - {records?.printer?.model} </Link>),
        },
        {
            accessor: "priority",
            width: "auto",
            title: "PRIORITY",
            render: (records) => <Badge className='text-uppercase' variant='filled' color={records.priority == 'high' ? 'red' : records.priority == 'critical' ? 'yellow' : records.priority == 'low' ? 'blue' : 'green'}>{records.priority}</Badge>,


        },
        {
            accessor: "counter",
            width: "auto",
            title: "Counter",
        },
        {
            accessor: "counter_file",
            width: "auto",
            title: "Count File",
            render: (records) => records.counter_file != null ? (<img width='50' className='cursor-pointer' onClick={() => modalHandle(records.counter_file)} height='50' src={records.counter_file} />) : ''
        },
        {
            accessor: "screenshot",
            width: "auto",
            title: "screenshot",
            render: (records) => records.screenshot != '' ? (<img width='50' className='cursor-pointer' onClick={() => modalHandle(records.screenshot)} height='50' src={records.screenshot} />) : ''

        },

        {
            accessor: "status",
            width: "auto",
            title: "STATUS",
            render: (records) => <Badge color={records.status == 'unAssign' ? 'red' : records.status == 'pending' ? 'yellow' : records.status == 'inProgress' ? 'blue' : 'green'}>{records.status}</Badge>,
            filter: (
                <TextInput
                    label="Status"
                    placeholder="Complaints status..."
                    icon={<IconSearch size={16} />}
                    value={statusQuery}
                    clearable
                    onChange={(e) => setStatusQuery(e.currentTarget.value)}
                />
            ),
        },

    ];




    return (
        <div>
            <ComplaintFileModal modalOpened={modalOpened} setModalOpened={setModalOpened} img={modalFile} />
            <div className="d-flex mb-3 justify-content-between align-items-end">
                <h3 className="m-0" onClick={() => dispatch(fetchComplaintsById(regionValue, formatDateToMMYYYY(value)))}>Complaints List</h3>
                <div className="d-flex gap-3">
                    <Select
                        size="xs"
                        defaultValue={regionValue}
                        value={regionValue}
                        label="Region"
                        placeholder="Pick Region"
                        onChange={(e) => setRegionValue(e)}
                        data={regions}

                        clearable
                    />
                    <MonthPickerInput
                        label="Complaint Month"
                        valueFormat="MMM YYYY"
                        defaultValue={new Date()}
                        value={value}
                        variant='filled'
                        onChange={setValue}
                    />
                </div>
            </div>
            <DataTable
                className="data-table-with-actions"
                withBorder
                records={records}
                withColumnBorders
                striped
                highlightOnHover
                verticalSpacing={10}
                columns={
                    product_columns
                }
                totalRecords={dataOfTable?.length}
                recordsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={(p) => setPage(p)}
                fetching={loading}
                minHeight={400}
                loaderVariant="dots"
                noRecordsText="No records found"
                paginationText={({ from, to, totalRecords }) =>
                    `Records ${from} - ${to} of ${totalRecords}`
                }
                paginationSize="md"
            />
        </div >
    )
}
function formatDateToMMYYYY(inputDate) {
    const date = new Date(inputDate);

    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (1-indexed)
    const year = date.getFullYear(); // Get the full year

    return `${month}-${year}`;
}

const ComplaintFileModal = ({ modalOpened, setModalOpened, img }) => {
    return (
        <Modal
            opened={modalOpened}
            onClose={() => setModalOpened(false)}
              
            size={'auto'}
            centered
            zIndex={999}
            radius={0}
            transitionProps={{ transition: 'fade', duration: 200 }}
        >
            <img src={img} className='modalImageScreenSHot' />
        </Modal>
    )
}
export default ComplaintsListTable