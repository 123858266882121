import { Button, FileInput, Select, TextInput } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { Fade, Zoom } from 'react-reveal'
import { storeBulkDepartment } from '../../redux/actions/departmentAction'
import { useDispatch } from 'react-redux'
import { errorMessage } from '../../globalFunctions'
import { useNavigate } from 'react-router-dom'

const StoreDepartmentPage = () => {

    const [department, setDepartment] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            if (department == null || department == '') {
                setIsLoading(false)
                return errorMessage('Please fill all the fields')
            }
            const formData = new FormData()
            formData.append('name', department)
            await dispatch(storeBulkDepartment(formData, 'single')).then((res) => {
                if (res.status == 'success') {
                    e.target.reset();
                    setDepartment(null);
                }
            })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }

    }

    return (
        <Fade>
            <div className="d-flex mb-3 justify-content-between align-items-end">
                <h3>Add Department</h3>
                <Button
                    className="text-uppercase"
                    variant="gradient"
                    size='sm'
                    gradient={{ from: 'rgba(5, 37, 92, 1)', to: 'rgba(0, 170, 255, 1)', deg: 172 }}
                    onClick={() => navigate('/department/list')}>
                    Departments List
                </Button>
            </div>
            <form onSubmit={handleSubmit}>
                <TextInput
                    value={department}
                    name='name'
                    onChange={(e) => setDepartment(e.target.value)}
                    label="Department Name"
                    className='mt-3'
                    placeholder="Department Name"
                    requried
                    withAsterisk

                />


                <Button
                    className='mt-5'
                    variant="gradient"
                    type='submit'
                    disabled={isLoading}
                    gradient={{ from: 'teal', to: 'green', deg: 172 }}
                >
                    Add Department
                </Button>
            </form>

        </Fade >
    )
}

export default StoreDepartmentPage