import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { regionConstants } from "../constants/regionConstants";

export const storeRegion = (value) => async (dispatch) => {

    dispatch({ type: regionConstants.STORE_REGION_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/region/store", value);
        if (res?.data?.status == "success") {

            dispatch({
                type: regionConstants.STORE_REGION_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(res?.data?.message)
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: regionConstants.STORE_REGION_FAILURE,
            });
            errorMessage(res?.data?.message)
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: regionConstants.STORE_REGION_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};
export const fetchRegion = () => async (dispatch) => {

    dispatch({ type: regionConstants.FETCH_REGION_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/region/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: regionConstants.FETCH_REGION_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: regionConstants.FETCH_REGION_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: regionConstants.FETCH_REGION_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};  