import { unitConstants } from "../constants/unitConstants";

export const unitReducer = (
    state = {
        loading: false,
        error: null,
        unitList: [],
    },
    action
) => {
    switch (action.type) {
        case unitConstants.FETCH_UNIT_REQUEST:
        case unitConstants.STORE_UNIT_REQUEST:
            return {
                ...state,
                loading: false,
            };

        case unitConstants.FETCH_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,
                unitList: action.payload,
            };
        case unitConstants.STORE_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,

            };


        case unitConstants.FETCH_UNIT_FAILURE:
        case unitConstants.STORE_UNIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}