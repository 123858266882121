import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { productCategoryConstants } from "../constants/productCategoryConstants";

export const storeProductCategory = (value) => async (dispatch) => {

    dispatch({ type: productCategoryConstants.STORE_PRODUCT_CATEGORY_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/product-category/store", value);
        if (res?.data?.status == "success") {
             
            dispatch({
                type: productCategoryConstants.STORE_PRODUCT_CATEGORY_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(res?.data?.message)
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: productCategoryConstants.STORE_PRODUCT_CATEGORY_FAILURE,
            });
            errorMessage(res?.data?.message)
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: productCategoryConstants.STORE_PRODUCT_CATEGORY_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};
export const fetchProductCategory = () => async (dispatch) => {

    dispatch({ type: productCategoryConstants.FETCH_PRODUCT_CATEGORY_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/product-category/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: productCategoryConstants.FETCH_PRODUCT_CATEGORY_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: productCategoryConstants.FETCH_PRODUCT_CATEGORY_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: productCategoryConstants.FETCH_PRODUCT_CATEGORY_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};  