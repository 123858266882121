import "./App.css";
import { BrowserRouter, useNavigate } from "react-router-dom";
import Router from "./router/Router";
import { MeetProvider } from "./components/layout/MeetProvider";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { VerifyDomain } from "./redux/actions/authActions";

function App() {
  
  return (
    <MeetProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </MeetProvider>
  );
}

export default App;
