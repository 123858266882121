import { Switch, Tabs } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import Fade from "react-reveal/Fade";
import { UseGetRole } from "../hooks/auth";

const AccountSetting = () => {
  const role = UseGetRole();
  const smallScreen = useMediaQuery("(max-width: 767px)");

  // Use state to manage the checked state of the switches
  const [smsNotificationChecked, setSmsNotificationChecked] = useState(false);
  const [emailNotificationChecked, setEmailNotificationChecked] =
    useState(false);

  return (
    <Fade>
      <div className="p-md-5 p-3 account-setting">
        <h2>Account Setting</h2>
        <Tabs
          color="orange"
          variant="pills"
          radius="xl"
          orientation={!smallScreen ? "vertical" : "horizontal"}
          defaultValue="account"
          className="mt-5"
        >
          <Tabs.List className="pb-4">
            <Tabs.Tab value="account">Account</Tabs.Tab>
            <Tabs.Tab value="notification">Notification</Tabs.Tab>
            <Tabs.Tab value="payment-method">Payment Method</Tabs.Tab>
            {role === "Lawyer" ? (
              <Tabs.Tab value="availability">Availability</Tabs.Tab>
            ) : null}
            <Link className="ms-3 mt-4 delete-account-link d-md-block d-none">
              Delete Account
            </Link>
          </Tabs.List>

          <Tabs.Panel value="notification" pl="xs" pt="lg">
            <p className="fw-bold fs-4">Notification Method</p>
            <div>
              <div className="d-flex justify-content-lg-between mt-2">
                <label>
                  Switch on the SMS notification if you want to receive
                  notification via SMS
                </label>
                <Switch
                  size="lg"
                  checked={smsNotificationChecked}
                  onChange={(value) => {
                    setSmsNotificationChecked(value);
                  }}
                />
              </div>

              <div className="d-flex justify-content-lg-between mt-2">
                <label>
                  Switch on the email notification if you want to receive
                  notification via email
                </label>
                <Switch
                  size="lg"
                  checked={emailNotificationChecked}
                  onChange={(value) => {
                    setEmailNotificationChecked(value);
                  }}
                />
              </div>
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="payment-method" pl="xs" pt="lg">
            Tax information tab content
          </Tabs.Panel>

        </Tabs>
      </div>
    </Fade>
  );
};

export default AccountSetting;
