import React from 'react'
import ProductListTable from '../../components/dataTables/products/productListTable'
import Fade from 'react-reveal'
const ProductListPage = () => {
    return (
        <Fade>
            <div className='products'>

                <ProductListTable />


            </div>
        </Fade>
    )
}

export default ProductListPage