import { requesterConstants } from "../constants/requesterConstants";

export const requesterReducer = (
    state = {
        loading: false,
        error: null,
        requesterList: [],
    },
    action
) => {
    switch (action.type) {
        case requesterConstants.FETCH_REQUESTER_REQUEST:
        case requesterConstants.STORE_REQUESTER_REQUEST:
        case requesterConstants.DESTROY_REQUESTER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case requesterConstants.FETCH_REQUESTER_SUCCESS:
            return {
                ...state,
                loading: false,
                requesterList: action.payload,
            };

        case requesterConstants.DESTROY_REQUESTER_SUCCESS:
            const updatedRequesterList = state.requesterList.filter((item) => item.id !== action.payload);
            return {
                ...state,
                loading: false,
                requesterList: updatedRequesterList,
            };
        case requesterConstants.STORE_REQUESTER_SUCCESS:
            return {
                ...state,
                loading: false,

            };


        case requesterConstants.DESTROY_REQUESTER_FAILURE:
        case requesterConstants.FETCH_REQUESTER_FAILURE:
        case requesterConstants.STORE_REQUESTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}