import custAxios, { attachToken, attachTokenWithFormAxios, formAxios } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { locationConstants } from "../constants/locationConstants";

export const storeLocation = (value) => async (dispatch) => {

    dispatch({ type: locationConstants.STORE_LOCATION_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/location/store", value);
        if (res?.data?.status == "success") {

            dispatch({
                type: locationConstants.STORE_LOCATION_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(res?.data?.message)
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: locationConstants.STORE_LOCATION_FAILURE,
            });
            errorMessage(res?.data?.message)
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: locationConstants.STORE_LOCATION_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};
export const storeBulkLocations = (data, EndPoint) => async (dispatch) => {

    dispatch({ type: locationConstants.STORE_LOCATION_REQUEST });
    try {
        attachTokenWithFormAxios();

        const res = await formAxios.post(`/admin/location/${EndPoint}`, data);
        if (res?.data?.status == "success") {
            dispatch({
                type: locationConstants.STORE_LOCATION_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(res?.data?.message)
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: locationConstants.STORE_LOCATION_FAILURE,
            });
            errorMessage(res?.data?.message)
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: locationConstants.STORE_LOCATION_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};
export const fetchLocation = () => async (dispatch) => {

    dispatch({ type: locationConstants.FETCH_LOCATION_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/location/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: locationConstants.FETCH_LOCATION_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: locationConstants.FETCH_LOCATION_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: locationConstants.FETCH_LOCATION_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};

export const fetchRegionLocation = (id) => async (dispatch) => {

    dispatch({ type: locationConstants.FETCH_REGION_LOCATION_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/location/region-list/" + id);

        if (res?.data?.status == "success") {
            dispatch({
                type: locationConstants.FETCH_REGION_LOCATION_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(JSON.stringify(res?.data?.message));
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: locationConstants.FETCH_REGION_LOCATION_FAILURE,
                payload: res?.data?.message,
            });
            errorMessage(JSON.stringify(res?.data?.message));
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: locationConstants.FETCH_REGION_LOCATION_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};

export const destroyLocation = (id) => async (dispatch) => {

    dispatch({ type: locationConstants.DESTROY_LOCATION_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get(`/admin/location/destroy/${id}`); 
        if (res?.data?.status == "success") { 
            dispatch({
                type: locationConstants.DESTROY_LOCATION_SUCCESS,
                payload: id,
            });
            successMessage('Locations successfully destroy');
            return "success";
        } else if (res?.data?.status == "warning") {

            dispatch({
                type: locationConstants.DESTROY_LOCATION_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: locationConstants.DESTROY_LOCATION_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error?.response?.data?.message || "Server Error");
    }
};
