import { webConstants } from "../constants/webContants";

export const webReducer = (
  state = {
    loading: false,
    error: null,
    featuredLawyers: [],
    foundLawyers: [],
    caseTypes: [],
    jurisdictions: [],
    blogsList: [],
    blog: {},
    categories: [],
    comments: [],
    LawyerById: {},
    subscribeData: {},
    tags: [],
    countres: {},
    webDetails: {},
    fetchFAQsList: [],
    fetchCarousel: {},
  },
  action
) => {
  switch (action.type) {
    case webConstants.FETCH_FEATURED_LAWYER_REQUEST: // ? Update personal info request
    case webConstants.FIND_LAWYER_REQUEST: // ? Update personal info request
    case webConstants.FETCH_PRACTICE_REQUEST: // ? Update personal info request
    case webConstants.GET_LAWYER_BY_ID_REQUEST: // ? Update personal info request
    case webConstants.GET_JURISDICTION_BY_COUNTRY_REQUEST: // ? Update personal info request
    case webConstants.GET_COUNTRIES_OF_JURISDICTIONS_REQUEST: // ? Update personal info request
    case webConstants.FETCH_WEB_DETAILS_REQUEST: // ? Update personal info request
    case webConstants.BLOGS_LIST_REQUEST: // ? Update personal info request
    case webConstants.GET_BLOG_BY_SLUG_REQUEST: // ? Update personal info request
    case webConstants.GET_BLOG_COMMENTS_REQUEST: // ? Update personal info request
    // case webConstants.LIKE_BLOG_REQUEST: // ? Update personal info request
    case webConstants.REPLY_OF_COMMENT_REQUEST: // ? Update personal info request
    case webConstants.BLOGS_CATEGORIES_REQUEST: // ? Update personal info request
    case webConstants.SUBSCRIBE_REQUEST: // ? Update personal info request
    case webConstants.UNSUBSCRIBE_REQUEST: // ? Update personal info request
    case webConstants.TAGS_REQUEST: // ? Update personal info request
    case webConstants.POST_A_MESSAGE_REQUEST: // ? Update personal info request
    case webConstants.FETCH_FAQS_REQUEST: // ? Update personal info request
    case webConstants.FETCH_CAROUSELS_REQUEST: // ? Update personal info request
      return {
        ...state,
        loading: true,
      };
    case webConstants.EDIT_COMMENT_OF_BLOG_REQUEST: // ? Update personal info request
    case webConstants.DELETE_COMMENT_OF_BLOG_REQUEST: // ? Update personal info request
    case webConstants.EDIT_REPLY_OF_COMMENT_REQUEST: // ? Update personal info request
    case webConstants.DELETE_REPLY_OF_COMMENT_REQUEST: // ? Update personal info request
      return {
        ...state,
        loaderOfButton: true,
      };

    case webConstants.FETCH_FEATURED_LAWYER_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        featuredLawyers: action.payload,
      };

    case webConstants.FIND_LAWYER_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        foundLawyers: action.payload,
      };

    case webConstants.FETCH_PRACTICE_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        caseTypes: action.payload,
      };
    case webConstants.SUBSCRIBE_SUCCESS: // ? Update personal info success
    case webConstants.UNSUBSCRIBE_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        subscribeData: action.payload,
      };
    case webConstants.GET_JURISDICTION_BY_COUNTRY_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        jurisdictions: action.payload,
      };

    case webConstants.FETCH_JURISDICTIONS_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        jurisdictions: action.payload,
      };
    case webConstants.GET_LAWYER_BY_ID_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        LawyerById: action.payload,
      };
    case webConstants.GET_COUNTRIES_OF_JURISDICTIONS_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };
    case webConstants.FETCH_WEB_DETAILS_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        webDetails: action.payload,
      };
    case webConstants.BLOGS_LIST_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        blogsList: action.payload,
      };
    case webConstants.GET_BLOG_BY_SLUG_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        blog: action.payload,
      };
    case webConstants.GET_BLOG_COMMENTS_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        comments: action.payload,
      };
    case webConstants.BLOGS_CATEGORIES_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case webConstants.TAGS_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        tags: action.payload,
      };
    case webConstants.FETCH_FAQS_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
        fetchFAQsList: action.payload,
      };
    case webConstants.FETCH_CAROUSELS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchCarousel: action.payload,
      };
    case webConstants.LIKE_BLOG_SUCCESS: // ? Update personal info success
    case webConstants.REPLY_OF_COMMENT_SUCCESS: // ? Update personal info success
    case webConstants.POST_A_MESSAGE_SUCCESS: // ? Update personal info success
      return {
        ...state,
        loading: false,
      };

    case webConstants.EDIT_COMMENT_OF_BLOG_SUCCESS: // ? Update personal info request
    case webConstants.DELETE_COMMENT_OF_BLOG_SUCCESS: // ? Update personal info request
    case webConstants.EDIT_REPLY_OF_COMMENT_SUCCESS: // ? Update personal info request
    case webConstants.DELETE_REPLY_OF_COMMENT_SUCCESS: // ? Update personal info request
      return {
        ...state,
        loaderOfButton: false,
      };

    case webConstants.FETCH_FEATURED_LAWYER_FAILURE: // ? Update personal info failure
    case webConstants.FIND_LAWYER_FAILURE: // ? Update personal info failure
    case webConstants.FETCH_PRACTICE_FAILURE: // ? Update personal info failure
    case webConstants.GET_LAWYER_BY_ID_FAILURE: // ? Update personal info failure
    case webConstants.GET_JURISDICTION_BY_COUNTRY_FAILURE: // ? Update personal info failure
    case webConstants.GET_COUNTRIES_OF_JURISDICTIONS_FAILURE: // ? Update personal info failure
    case webConstants.FETCH_WEB_DETAILS_FAILURE: // ? Update personal info failure
    case webConstants.GET_BLOG_BY_SLUG_FAILURE: // ? Update personal info failure
    case webConstants.GET_BLOG_COMMENTS_FAILURE: // ? Update personal info failure
    case webConstants.LIKE_BLOG_FAILURE: // ? Update personal info failure
    case webConstants.REPLY_OF_COMMENT_FAILURE: // ? Update personal info failure
    case webConstants.BLOGS_CATEGORIES_FAILURE: // ? Update personal info failure
    case webConstants.SUBSCRIBE_FAILURE: // ? Update personal info failure
    case webConstants.UNSUBSCRIBE_FAILURE: // ? Update personal info failure
    case webConstants.TAGS_FAILURE: // ? Update personal info failure
    case webConstants.POST_A_MESSAGE_FAILURE: // ? Update personal info failure
    case webConstants.FETCH_FAQS_FAILURE: // ? Update personal info failure
    case webConstants.FETCH_CAROUSELS_FAILURE: // ? Update personal info failure
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case webConstants.EDIT_COMMENT_OF_BLOG_FAILURE: // ? Update personal info request
    case webConstants.DELETE_COMMENT_OF_BLOG_FAILURE: // ? Update personal info request
    case webConstants.EDIT_REPLY_OF_COMMENT_FAILURE: // ? Update personal info request
    case webConstants.DELETE_REPLY_OF_COMMENT_FAILURE: // ? Update personal info request
      return {
        ...state,
        loaderOfButton: false,
        error: action.payload,
      };

    default: // ? defaaaalt case yk...!
      return state;
  }
};
