import { Box, Button, FileInput, Image, LoadingOverlay, Select, TextInput, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Fade } from "react-reveal";
import { storePrinter } from "../../redux/actions/printerAction";
import { fetchRegion } from "../../redux/actions/regionAction";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import noImageFile from '../../assets/images/attechment/noimage.jpg'
import { searchProduct } from "../../redux/actions/productAction";


const initialValue = {
    date: '',
    reference_no: '',
    note: '',
    attachment: null,
    supplier: '',
    region: '',
    file: null,
    search: '',
    subTotal: 0,

}


const AddPurchasePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState()
    const [regionData, setRegionDeta] = useState([])
    const [productData, setProductData] = useState([])
    const [inputValue, setInputValue] = useState(initialValue)
    const { regionList } = useSelector((state) => state?.region)
    const { searchLoading, searchProducts } = useSelector((state) => state?.products)

    useEffect(() => {
        dispatch(fetchRegion())
    }, []);

    useEffect(() => {
        const items = regionList.map((item) => ({ value: item.id, label: item.name }));

        setRegionDeta(items)
    }, [regionList]);


    useEffect(() => {
        searchProducts.forEach((product, i) => {
            const existingProduct = productData.find((item) => item.id === product.id);

            if (!existingProduct) {

                searchProducts[i] = { ...searchProducts[i], purchaseQuantity: 1, cost: 1, item_total: 1 };
                setProductData([searchProducts[i], ...productData]);
            }
        });

        // Concatenate the updated productData with searchProducts

    }, [searchProducts]);


    useEffect(() => {

    }, [productData])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            dispatch(storePrinter(inputValue)).then((res) => {
                if (res == 'success') {
                    setInputValue(initialValue)
                }
            })
        } catch (error) {

        }
        setLoading(false)
    }

    const handleInput = (value, name) => {
        const updatedInputValue = {
            ...inputValue,
            [name]: value
        };
        setInputValue(updatedInputValue);
    };

    const handleSeachProduct = async (e) => {

        let val = e.trim();
        if (val.length > 2) {
            await dispatch(searchProduct({ title: val }))
            inputValue.search = '';
        }
    }
    return (

        <Fade>
            <div className="PruchasePage mt-5">
                <div className="d-flex mb-3 justify-content-between align-items-end">
                    <h3>Add Purchase</h3>
                    <Button
                        className="text-uppercase"
                        variant="gradient"
                        size='sm'
                        gradient={{ from: 'rgba(5, 37, 92, 1)', to: 'rgba(0, 170, 255, 1)', deg: 172 }}
                        onClick={() => navigate('/purchase/list')}>
                        Purchase List
                    </Button>
                </div>
                <form onSubmit={handleSubmit}>
                    <DateInput
                        valueFormat="DD-MM-YYYY"
                        onChange={(e) => handleInput(e, 'date')}
                        value={inputValue.date}
                        label="Date"
                        placeholder="Date"
                        variant="filled" />

                    <TextInput
                        value={inputValue.reference_no}
                        onChange={(e) => handleInput(e.target.value, 'reference_no')}
                        label="Reference no"
                        className='mt-3 '
                        placeholder="Reference Name"
                        requried />

                    <Select
                        onChange={(e) => handleInput(e, 'region')}
                        value={inputValue.region}
                        label="Regions"
                        placeholder="Please Select"
                        data={regionData}
                        searchable
                        className='mt-3 w-100  required-input'
                    />

                    <Textarea
                        onChange={(e) => handleInput(e.currentTarget.value, 'note')}
                        value={inputValue.note}
                        variant="filled"
                        label="Note"
                        placeholder="Note"
                        className='mt-3 w-100  required-input'
                        minRows={3}
                    />

                    <FileInput
                        onChange={(e) => handleInput(e, 'file')}
                        accept="image/png,image/jpeg"
                        label="Attechment"
                        placeholder="Upload files"
                        className='mt-3 w-100'
                        clearable
                    />
                    <div className="d-flex align-items-end gap-3">
                        <TextInput
                            onChange={(e) => handleInput(e.currentTarget.value, 'search')}
                            label="Add Product"
                            value={inputValue.search}
                            placeholder="Product name"
                            clearable
                            className='mt-3 w-100  required-input'
                        />
                        <Button
                            onClick={() => handleSeachProduct(inputValue.search)}
                            variant="filled"
                            color="gray"
                            size="sm"
                        >
                            SEARCH
                        </Button>
                    </div>
                    <Box pos="relative">

                        <LoadingOverlay visible={searchLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                        <table id="product-table" className="table mt-3 table-striped table-bordered mb-0">
                            <thead>
                                <tr className="bg-success text-light">
                                    <th className="w-35 text-center">
                                        Product
                                    </th>
                                    <th className="w-10 text-center">
                                        Available
                                    </th>
                                    <th className="w-10 text-center">
                                        Quantity
                                    </th>
                                    <th className="w-10 text-center">
                                        Cost
                                    </th>

                                    <th className="w-10 text-center">
                                        Item Total
                                    </th>
                                    <th className="w-5 text-center">
                                        <i className="fa fa-trash-o"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {productData?.map((item, i) => {
                                    // Calculate the item total
                                    const itemTotal = item.purchaseQuantity * item.cost;


                                    return (
                                        <tr key={item.id} className="product-row" data-item-id={item.id}>
                                            <td className="text-center" style={{ minWidth: '100px' }} data-title="Product Name">
                                                <span className="name" id={'name-' + item.id}>{item.title}</span>
                                            </td>
                                            <td className="text-center">
                                                <span className="text-center available">{item.quantity}</span>
                                            </td>
                                            <td style={{ padding: '2px' }}>
                                                <input
                                                    className="form-control input-sm text-center quantity"
                                                    type="text"
                                                    value={item.purchaseQuantity}
                                                    onChange={(e) => {


                                                        // Update the purchaseQuantity of the specific item
                                                        const updatedProductData = [...productData];
                                                        updatedProductData[i].purchaseQuantity = e.target.value;
                                                        updatedProductData[i].item_total = itemTotal; // Update item total
                                                        setInputValue({
                                                            ...inputValue,
                                                            subTotal: initialValue.subTotal + itemTotal
                                                        });
                                                        setProductData(updatedProductData);
                                                    }}
                                                />
                                            </td>
                                            <td style={{ padding: '2px', minWidth: '80px' }} data-title="Purchase Price">
                                                <input
                                                    className="form-control input-sm text-center purchase-price"
                                                    type="text"
                                                    value={item.cost}
                                                    onChange={(e) => {
                                                        // Update the cost of the specific item
                                                        const updatedProductData = [...productData];
                                                        updatedProductData[i].cost = e.target.value;
                                                        updatedProductData[i].item_total = itemTotal; // Update item total
                                                        setInputValue({
                                                            ...inputValue,
                                                            subTotal: initialValue.subTotal + itemTotal
                                                        });
                                                        setProductData(updatedProductData);
                                                    }}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <span className="tax tax-amount-view">{itemTotal}</span>
                                            </td>
                                            <td className="text-center">
                                                <i
                                                    className="fa fa-close text-red pointer cursor-pointer remove"
                                                    onClick={() => {
                                                        // Remove the specific item from productData
                                                        const updatedProductData = productData.filter((row) => row.id !== item.id);
                                                        setProductData(updatedProductData);
                                                    }}
                                                    title="Remove"
                                                ></i>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                <tr className="bg-gray">
                                    <th className="text-end" colSpan="4">
                                        Subtotal
                                    </th>
                                    <th className="col-sm-2 text-end">
                                        <span id="total-amount-view">
                                        </span>
                                    </th>
                                    <th className="w-25p">&nbsp;</th>
                                </tr>
                                <tr className="bg-gray">
                                    <th className="text-end" colSpan="4">
                                        Order Tax (%)
                                    </th>
                                    <th className="col-sm-2 text-end">
                                        <input
                                            id="order-tax"


                                            className="text-end form-control"
                                            type="text"
                                            name="order-tax"
                                            autoComplete="off" />
                                    </th>
                                    <th className="w-25p">&nbsp;</th>
                                </tr>
                                <tr className="bg-gray">
                                    <th className="text-end" colSpan="4">
                                        Shipping Charge
                                    </th>
                                    <th className="col-sm-2 text-end">
                                        <input id="shipping-amount" className="text-end form-control" type="text" name="shipping-amount" autoComplete="off" />
                                    </th>
                                    <th className="w-25p">&nbsp;</th>
                                </tr>
                                <tr className="bg-gray">
                                    <th className="text-end" colSpan="4">
                                        Other Charge
                                    </th>
                                    <th className="col-sm-2 text-end">
                                        <input id="others-charge" className="text-end form-control" type="text" autoComplete="off" />
                                    </th>
                                    <th className="w-25p">&nbsp;</th>
                                </tr>
                                <tr className="bg-gray">
                                    <th className="text-end" colSpan="4">
                                        Discount
                                    </th>
                                    <th className="col-sm-2 text-end">
                                        <input id="discount-amount" className="text-end form-control" type="text" name="discount-amount" autoComplete="off" />
                                    </th>
                                    <th className="w-25p">&nbsp;</th>
                                </tr>
                                <tr className="bg-yellow text-light">
                                    <th className="text-end" colSpan="4">
                                        Payable Amount
                                    </th>
                                    <th className="col-sm-2 text-end">
                                        <h4 className="text-center">
                                            <b className="ng-binding">120.00</b>
                                        </h4>
                                    </th>
                                    <th className="w-25p">&nbsp;</th>
                                </tr>
                                <tr className="bg-primary text-light">
                                    <th className="text-end" colSpan="4">
                                        Payment Method
                                    </th>
                                    <th className="col-sm-2 text-center">
                                        <select id="pmethod-id" className="form-control" tabIndex="-1" aria-hidden="true">
                                            <option value="cod">Cash on Delivery</option>
                                            <option value="online">Online</option>
                                            <option value="check">Check</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </th>
                                    <th className="w-25p">&nbsp;</th>
                                </tr>
                                <tr className="bg-primary text-light">
                                    <th className="text-end" colSpan="4">
                                        Paid Amount
                                    </th>
                                    <th className="col-sm-2 text-end">
                                        <input id="paid-amount" className="text-center form-control paidAmount" type="text" name="paid-amount" autoComplete="off" />
                                    </th>
                                    <th className="w-25p">&nbsp;</th>
                                </tr>
                                <tr className="bg-gray">
                                    <th colSpan="2" className="w-10 text-end">
                                        Due Amount
                                    </th>
                                    <th colSpan="2" className="w-70 bg-danger text-light text-center">
                                        <span className="ng-binding">0.00</span>
                                    </th>
                                    <th colSpan="2">&nbsp;</th>
                                </tr>
                                <tr className="bg-gray">
                                    <th colSpan="2" className="w-10 text-end">
                                        Change Amount
                                    </th>
                                    <th colSpan="2" className="w-70 bg-success text-light text-center">
                                        <span className="ng-binding">0.00</span>
                                    </th>
                                    <th colSpan="2">&nbsp;</th>
                                </tr>
                            </tfoot>
                        </table>
                    </Box>

                </form>
            </div >
        </Fade >
    )
}

export default AddPurchasePage