import React, { useEffect, useState } from 'react'
import { Modal, Button, TextInput, Select } from '@mantine/core';
import { AiOutlineDownload, AiOutlinePrinter } from 'react-icons/ai'
import { IconDownload } from '@tabler/icons-react';
const PrinterDetailModal = ({ setModelPopup, modelPopup, data }) => {

    const printQRCode = (qrCodeUrl, model, brand, serial_number) => {
        const printWindow = window.open('', '', 'width=200,height=200');
        printWindow.document.open();
        printWindow.document.write('<html><body>');
        printWindow.document.write(

            `
            <div style="width:200px;height:200px">
            <img src="${qrCodeUrl}" alt="QR Code" width="200" height="200" />
            <p style="margin-top:10px">  ${brand} -  ${model} -   ${serial_number}</p>
            
            </div>
            `
        );
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };


    return (
        <div>
            <Modal opened={modelPopup} onClose={() => setModelPopup(false)} title={data?.name} size={'auto'}>
                <div className='w-100 d-flex align-items-center flex-column justify-content-center'>
                    <img src={data?.qrcodes} width={200} height={200} />
                    <p className="w-100 px-2  mt-3"> {data?.brand} -  {data?.model} -   {data?.serial_number}</p>
                    <div className="w-100 d-flex justify-content-between gap-2 mt-3">

                        <a className='btn btn-primary' href={data?.qrcodes} download > <IconDownload size={18} /> Download</a>

                        <Button
                            onClick={() => printQRCode(data?.qrcodes, data?.model, data?.brand, data?.serial_number)}
                            variant="light"

                        >
                            <AiOutlinePrinter className='fa-2x mr-2' />
                            Print
                        </Button>
                    </div>
                </div>

            </Modal>

        </div>
    )
}

export default PrinterDetailModal