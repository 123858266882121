import custAxios, { attachToken, attachTokenWithFormAxios, formAxios } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { departmentConstants } from "../constants/departmentConstants";

export const storeDepartment = (value) => async (dispatch) => {

    dispatch({ type: departmentConstants.STORE_DEPARTMENT_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/department/store", value);
        if (res?.data?.status == "success") {

            dispatch({
                type: departmentConstants.STORE_DEPARTMENT_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(JSON.stringify(res?.data?.message))
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: departmentConstants.STORE_DEPARTMENT_FAILURE,
            });
            errorMessage(JSON.stringify(res?.data?.message))
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: departmentConstants.STORE_DEPARTMENT_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};
export const storeBulkDepartment = (data, storeType) => async (dispatch) => {

    dispatch({ type: departmentConstants.STORE_DEPARTMENT_REQUEST });
    try {
        attachTokenWithFormAxios();
        let http = null;
        if (storeType == 'bulk') {
            http = '/admin/department/bulk-store'

        } else {
            http = '/admin/department/store'
        }
        const res = await formAxios.post(http, data);
        if (res?.data?.status == "success") {
            dispatch({
                type: departmentConstants.STORE_DEPARTMENT_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(JSON.stringify(res?.data?.message))
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: departmentConstants.STORE_DEPARTMENT_FAILURE,
            });
            errorMessage(JSON.stringify(res?.data?.message))
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: departmentConstants.STORE_DEPARTMENT_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};
export const fetchDepartment = () => async (dispatch) => {

    dispatch({ type: departmentConstants.FETCH_DEPARTMENT_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/department/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: departmentConstants.FETCH_DEPARTMENT_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: departmentConstants.FETCH_DEPARTMENT_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: departmentConstants.FETCH_DEPARTMENT_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};

export const destroyDepartment = (id) => async (dispatch) => {

    dispatch({ type: departmentConstants.DESTROY_DEPARTMENT_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get(`/admin/department/destroy/${id}`);

        if (res?.data?.status == "success") {
            dispatch({
                type: departmentConstants.DESTROY_DEPARTMENT_SUCCESS,
                payload: id,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: departmentConstants.DESTROY_DEPARTMENT_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: departmentConstants.DESTROY_DEPARTMENT_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};
