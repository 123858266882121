import React, { useEffect } from "react";
import { UseGetRole, useAuth } from "../hooks/auth";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { VerifyDomain } from "../redux/actions/authActions";

const CommonRoute = ({ Component }) => {
  const isAuthenticated = useAuth();
  const { verifyLoading, DomainVarify } = useSelector((state) => state?.auth);

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(VerifyDomain())
  }, []);


  const role = UseGetRole()
  if (DomainVarify) {
    if (!isAuthenticated) {
      return <Navigate to="/login" />;

    } else {
      if (role === "admin" || role === "inventory-manager") {
        return <Component />;
      } else {
        return <Navigate to="/not-found" />;
      }

    }
  } else {
    return <Navigate to="/expired" />;
  }
};

export default CommonRoute;
