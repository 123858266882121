import { customerConstants } from "../constants/customerContants";

export const customerReducer = (
    state = {
        loading: false,
        error: null,
        customerList: [],
    },
    action
) => {
    switch (action.type) {
        case customerConstants.DESTROY_CUSTOMER_REQUEST:
        case customerConstants.FETCH_CUSTOMER_REQUEST:
        case customerConstants.STORE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: true,
            };

        case customerConstants.FETCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customerList: action.payload,
            };
        case customerConstants.STORE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case customerConstants.DESTROY_CUSTOMER_SUCCESS:
            const updatedCustomerList = state.customerList.filter((item) => item.id !== action.payload);
            return {
                ...state,
                loading: false,
                customerList: updatedCustomerList,
            };


        case customerConstants.DESTROY_CUSTOMER_FAILURE:
        case customerConstants.FETCH_CUSTOMER_FAILURE:
        case customerConstants.STORE_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}