import React from 'react'
import PrinterListTable from '../../components/dataTables/printers/printersListTable'

const PrinterListPage = () => {
    return (
        <div>
            <PrinterListTable />
        </div>
    )
}

export default PrinterListPage