import { regionConstants } from "../constants/regionConstants";

export const regionReducer = (
    state = {
        loading: false,
        error: null,
        regionList: [],
    },
    action
) => {
    switch (action.type) {
        case regionConstants.FETCH_REGION_REQUEST:
        case regionConstants.STORE_REGION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case regionConstants.FETCH_REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                regionList: action.payload,
            };
        case regionConstants.STORE_REGION_SUCCESS:
            return {
                ...state,
                loading: false,

            };


        case regionConstants.FETCH_REGION_FAILURE:
        case regionConstants.STORE_REGION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}