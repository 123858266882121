import React, { useEffect } from 'react'
import { UseGetRole, useAuth } from '../hooks/auth';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { VerifyDomain } from '../redux/actions/authActions';

const AdminRoute = ({ Component }) => {
    const isAuthenticated = useAuth();
    const { verifyLoading, DomainVarify } = useSelector((state) => state?.auth);

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(VerifyDomain())
    }, [Component]); 
    const role = UseGetRole()
    if (DomainVarify) {
        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        } else {
            if (role === "admin") {
                return <Component />;
            } else {
                return <Navigate to="/not-found" />;
            }
        }
    } else {
        return <Navigate to="/expired" />;
    }
}

export default AdminRoute