import { productCategoryConstants } from "../constants/productCategoryConstants";

export const productCategoryReducer = (
    state = {
        loading: false,
        error: null,
        productCategory: [],
    },
    action
) => {
    switch (action.type) {
        case productCategoryConstants.FETCH_PRODUCT_CATEGORY_REQUEST:
        case productCategoryConstants.STORE_PRODUCT_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case productCategoryConstants.FETCH_PRODUCT_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                productCategory: action.payload,
            };
        case productCategoryConstants.STORE_PRODUCT_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,

            };


        case productCategoryConstants.FETCH_PRODUCT_CATEGORY_FAILURE:
        case productCategoryConstants.STORE_PRODUCT_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}