
export const printerConstants = {
    FETCH_PRINTER_MODEL_REQUEST: "FETCH_PRINTER_MODEL_REQUEST",
    FETCH_PRINTER_MODEL_SUCCESS: "FETCH_PRINTER_MODEL_SUCCESS",
    FETCH_PRINTER_MODEL_FAILURE: "FETCH_PRINTER_MODEL_FAILURE",

    STORE_PRINTER_REQUEST: "STORE_PRINTER_REQUEST",
    STORE_PRINTER_SUCCESS: "STORE_PRINTER_SUCCESS",
    STORE_PRINTER_FAILURE: "STORE_PRINTER_FAILURE",

    STORE_PRINTER_MODEL_REQUEST: "STORE_PRINTER_MODEL_REQUEST",
    STORE_PRINTER_MODEL_SUCCESS: "STORE_PRINTER_MODEL_SUCCESS",
    STORE_PRINTER_MODEL_FAILURE: "STORE_PRINTER_MODEL_FAILURE",


    FETCH_MODEL_BY_BRAND_REQUEST: "FETCH_MODEL_BY_BRAND_REQUEST",
    FETCH_MODEL_BY_BRAND_SUCCESS: "FETCH_MODEL_BY_BRAND_SUCCESS",
    FETCH_MODEL_BY_BRAND_FAILURE: "FETCH_MODEL_BY_BRAND_FAILURE",

    FETCH_PRINTER_BY_REGION_REQUEST: "FETCH_PRINTER_BY_REGION_REQUEST",
    FETCH_PRINTER_BY_REGION_SUCCESS: "FETCH_PRINTER_BY_REGION_SUCCESS",
    FETCH_PRINTER_BY_REGION_FAILURE: "FETCH_PRINTER_BY_REGION_FAILURE",

    FETCH_PRINTER_BY_ID_REQUEST: "FETCH_PRINTER_BY_ID_REQUEST",
    FETCH_PRINTER_BY_ID_SUCCESS: "FETCH_PRINTER_BY_ID_SUCCESS",
    FETCH_PRINTER_BY_ID_FAILURE: "FETCH_PRINTER_BY_ID_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
