import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { complaintsConstants } from "../constants/complaintsContants";

export const fetchComplaintsById = (id, date) => async (dispatch) => {

    dispatch({ type: complaintsConstants.FETCH_COMPLAINTS_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get(`/admin/complaint/list/${id}/${date}`);
        if (res?.data?.status == "success") {
            dispatch({
                type: complaintsConstants.FETCH_COMPLAINTS_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: complaintsConstants.FETCH_COMPLAINTS_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: complaintsConstants.FETCH_COMPLAINTS_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};  