import React from 'react'
import DepartmentListTable from '../../components/dataTables/departments/DepartmentListTable'
import { Fade } from 'react-reveal'

const ListDepartmentPage = () => {
  return (
    <Fade>
    <DepartmentListTable/>
    </Fade>
  )
}

export default ListDepartmentPage