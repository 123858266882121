import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { printerConstants } from "../constants/printerConstants";

export const storePrinter = (value) => async (dispatch) => {

    dispatch({ type: printerConstants.STORE_PRINTER_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/printer/store", value);
        if (res?.data?.status == "success") {

            dispatch({
                type: printerConstants.STORE_PRINTER_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(JSON.stringify(res?.data?.message))
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: printerConstants.STORE_PRINTER_FAILURE,
            });
            errorMessage(JSON.stringify(res?.data?.message))
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: printerConstants.STORE_PRINTER_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(JSON.stringify(error.response.data.message));
    }
};

export const storePrinterModel = (value) => async (dispatch) => {

    dispatch({ type: printerConstants.STORE_PRINTER_MODEL_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/product-model/store", value);
        if (res?.data?.status == "success") {

            dispatch({
                type: printerConstants.STORE_PRINTER_MODEL_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(JSON.stringify(res?.data?.message))
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: printerConstants.STORE_PRINTER_MODEL_FAILURE,
            });
            errorMessage(JSON.stringify(res?.data?.message))
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: printerConstants.STORE_PRINTER_MODEL_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(JSON.stringify(error.response.data.message));
    }
};

export const fetchPrinterModel = () => async (dispatch) => {

    dispatch({ type: printerConstants.FETCH_PRINTER_MODEL_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/product-model/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: printerConstants.FETCH_PRINTER_MODEL_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: printerConstants.FETCH_PRINTER_MODEL_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: printerConstants.FETCH_PRINTER_MODEL_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};


export const FetchModelByBrand = (id) => async (dispatch) => {

    dispatch({ type: printerConstants.FETCH_MODEL_BY_BRAND_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/product-model/list-brand/" + id);

        if (res?.data?.status == "success") {

            dispatch({
                type: printerConstants.FETCH_MODEL_BY_BRAND_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: printerConstants.FETCH_MODEL_BY_BRAND_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: printerConstants.FETCH_MODEL_BY_BRAND_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};


export const FetchPrinterByRegion = (id) => async (dispatch) => {

    dispatch({ type: printerConstants.FETCH_PRINTER_BY_REGION_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/printer/list/" + id);

        if (res?.data?.status == "success") {

            dispatch({
                type: printerConstants.FETCH_PRINTER_BY_REGION_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: printerConstants.FETCH_PRINTER_BY_REGION_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: printerConstants.FETCH_PRINTER_BY_REGION_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};

export const FetchPrinterById = (id) => async (dispatch) => {

    dispatch({ type: printerConstants.FETCH_PRINTER_BY_ID_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/printer/show/" + id);

        if (res?.data?.status == "success") {

            dispatch({
                type: printerConstants.FETCH_PRINTER_BY_ID_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: printerConstants.FETCH_PRINTER_BY_ID_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: printerConstants.FETCH_PRINTER_BY_ID_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};

