import React, { createContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Burger, SegmentedControl, Tabs } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import AdminAside from "../components/AdminAside";
import { UseGetRole, getToken } from "../hooks/auth";
import { logout } from "../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { GoLaw } from "react-icons/go";
import { FaUserTie, FaUserAlt } from "react-icons/fa";
import { ImHammer2 } from "react-icons/im";
import Fade from "react-reveal/Fade";
import DataTableWithActions from "../components/admin/DataTableWithActions";
import {
  addClient,
  addLawyer,
  deleteClient,
  deleteLawyer,
  fetchClientList,
  fetchLawyerList,
  updateClient,
  updateLawyer,
} from "../redux/actions/adminActions";

import { IoChatbubblesOutline } from "react-icons/io5";
import { AiOutlineWechat } from "react-icons/ai";

export const HandleLogoutContext = createContext();

const DashboardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = UseGetRole();

  const [opened, { toggle, close }] = useDisclosure(false);
  const screen_1200 = useMediaQuery("(max-width: 1200px)");

  const handleMenuBtn = () => {
    toggle();
  };

  const handleLogout = async () => {
    const res = await dispatch(logout());
    navigate("/");
  };
  return (
    <HandleLogoutContext.Provider value={handleLogout}>
      <Fade>
        <div className="dashboard-body position-relative">
          <span
            className="aside-toggler  xl-screen-none position-absolute"
            style={{ margin: "2.6rem 1.6rem" }}
          >
            <Burger color="#D5AA6D" opened={opened} onClick={toggle} />
          </span>
          <div className={`aside-container ${opened ? "active" : ""}`}>

            <AdminAside
              handleMenuBtn={handleMenuBtn}
              opened={opened}
              close={close}
            /> 

          </div>
          {opened && screen_1200 && (
            <div className="aside-overlay" onClick={close}></div>
          )}
          <Container
            fluid
            className="py-xs-3 py-2 ps-xl-0 px-xs-3 px-2 h-100 min-vh-100"
          >
            <div className="content-white-container h-100">
              <div className="search-bar position-sticky top-0">

              </div>
              <div className="outlet">
                <Outlet />
              </div>
            </div>
          </Container>
        </div>
      </Fade>
      <ChatIcon />
    </HandleLogoutContext.Provider>
  );
};

export default DashboardLayout;

const TableOfDashboard = () => {
  const { lawyers, clients } = useSelector((state) => state.admin);
  const [activeTab, setActiveTab] = useState("lawyers");
  const handleTabChange = (value) => {
    setActiveTab(value);
  };
  const tabs = [
    { label: "Lawyers", value: "lawyers" },
    { label: "Clients", value: "clients" },
  ];
  return (
    <>
      <Container fluid className="pb-md-5 pb-0 mb-md-5 px-md-4 px-3">
        <div className="d-flex justify-content-center mb-4">
          <SegmentedControl
            value={activeTab}
            onChange={handleTabChange}
            data={tabs}
          // style={{  color: "#da954c" }}
          />
        </div>

        {/* Lawyers' Tab */}
        {activeTab === "lawyers" && (
          <DataTableWithActions
            data={lawyers}
            fetchDispatch={fetchLawyerList}
            addDispatch={addLawyer}
            updateDispatch={updateLawyer}
            deleteDispatch={deleteLawyer}
            buttonText="Add Lawyer"
            tab={"lawyers"}
            fromDash={true}
          />
        )}
        {/* Clients' Tab */}
        {activeTab === "clients" && (
          <DataTableWithActions
            data={clients}
            fetchDispatch={fetchClientList}
            addDispatch={addClient}
            updateDispatch={updateClient}
            deleteDispatch={deleteClient}
            buttonText="Add Client"
            tab={"clients"}
            fromDash={true}
          />
        )}
      </Container>
    </>
  );
};

export const ChatIcon = () => {
  const role = UseGetRole();

  return (
    <>
      {(role === "Lawyer" || role === "Client") && (
        <div className="chat-icon-container">
          <Link to={"/chat"}>
            <button className="chat-icon-button">
              <AiOutlineWechat style={{ fontSize: "30px", color: "white" }} />
            </button>
          </Link>
        </div>
      )}
    </>
  );
};
