import custAxios, {
  attachToken,
  attachTokenWithFormAxios,
  formAxios,
} from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { adminConstants } from "../constants/adminContants";

// ? Practice Actions
export const fetchPracticeList = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_PRACTICE_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get("super-admin/area-expertise/list");
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_PRACTICE_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_PRACTICE_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_PRACTICE_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_PRACTICE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const addPractice = (values) => async (dispatch) => {
  dispatch({ type: adminConstants.ADD_PRACTICE_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(
      "super-admin/area-expertise/create",
      values
    );
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.ADD_PRACTICE_SUCCESS,
      });
      dispatch(fetchPracticeList());
      successMessage("Practice added successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.ADD_PRACTICE_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.ADD_PRACTICE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const updatePractice = (id, values) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_PRACTICE_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(
      `super-admin/area-expertise/update/${id}`,
      values
    );
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_PRACTICE_SUCCESS,
      });
      dispatch(fetchPracticeList());
      successMessage("Practice updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_PRACTICE_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_PRACTICE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const deletePractice = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_PRACTICE_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`super-admin/area-expertise/destroy/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_PRACTICE_SUCCESS,
      });
      dispatch(fetchPracticeList());
      successMessage("Practice Deleted successfully");
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_PRACTICE_FAILURE,
        payload: res?.data?.message,
      });
      errorMessage(res?.data?.message);
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_PRACTICE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

// ? Jurisdictions Actions
export const fetchJurisdictionList = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_JURISDICTIONS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get("super-admin/jurisdiction/list");
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_JURISDICTIONS_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_JURISDICTIONS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_JURISDICTIONS_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_JURISDICTIONS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const addJurisdiction = (values) => async (dispatch) => {
  dispatch({ type: adminConstants.ADD_JURISDICTION_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post("super-admin/jurisdiction/create", values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.ADD_JURISDICTION_SUCCESS,
      });
      dispatch(fetchJurisdictionList());
      successMessage("Jurisdiction added successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.ADD_JURISDICTION_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.ADD_JURISDICTION_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const updateJurisdiction = (id, values) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_JURISDICTION_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(
      `super-admin/jurisdiction/update/${id}`,
      values
    );
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_JURISDICTION_SUCCESS,
      });
      dispatch(fetchJurisdictionList());
      successMessage("Jurisdiction updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_JURISDICTION_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_JURISDICTION_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const deleteJurisdiction = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_JURISDICTION_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`super-admin/jurisdiction/destroy/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_JURISDICTION_SUCCESS,
      });
      dispatch(fetchJurisdictionList());
      successMessage("Jurisdiction Deleted successfully");
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_JURISDICTION_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_JURISDICTION_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

// ? Moderators Actions
export const fetchModeratorList = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_MODERATORS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get("super-admin/admin/list");
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_MODERATORS_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_MODERATORS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_MODERATORS_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_MODERATORS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const addModerator = (values) => async (dispatch) => {
  dispatch({ type: adminConstants.ADD_MODERATOR_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post("super-admin/admin/create", values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.ADD_MODERATOR_SUCCESS,
      });
      dispatch(fetchModeratorList());
      successMessage("Moderator added successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.ADD_MODERATOR_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.ADD_MODERATOR_FAILURE,
      payload: error?.response?.data || "Server Error",
    });
    errorMessage("Internal server error");
  }
};

export const updateModerator = (id, values) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_MODERATOR_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`super-admin/admin/update/${id}`, values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_MODERATOR_SUCCESS,
      });
      dispatch(fetchModeratorList());
      successMessage("Moderator updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_MODERATOR_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_MODERATOR_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const deleteModerator = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_MODERATOR_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`super-admin/admin/destroy/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_MODERATOR_SUCCESS,
      });
      dispatch(fetchModeratorList());
      successMessage("Moderator Deleted successfully");
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_MODERATOR_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_MODERATOR_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const fetchDashboard = () => async (dispatch) => {
  dispatch({ type: adminConstants.GET_DASHBOARD_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get("super-admin/dashboard");
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.GET_DASHBOARD_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.GET_DASHBOARD_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.GET_DASHBOARD_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
// ? Lawyers Actions
export const fetchLawyerList = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_LAWYERS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get("super-admin/lawyer/list");
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_LAWYERS_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_LAWYERS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_LAWYERS_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_LAWYERS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const addLawyer = (values) => async (dispatch) => {
  dispatch({ type: adminConstants.ADD_LAWYER_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post("super-admin/lawyer/create", values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.ADD_LAWYER_SUCCESS,
      });
      dispatch(fetchLawyerList());
      successMessage("Lawyer added successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.ADD_LAWYER_FAILURE,
        payload: res?.data?.message,
      });
      errorMessage(res?.data?.message);
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.ADD_LAWYER_FAILURE,
      payload: error?.response?.data || "Server Error",
    });
    errorMessage("Internal server error");
  }
};

export const updateLawyer = (id, values) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_LAWYER_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`super-admin/lawyer/update/${id}`, values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_LAWYER_SUCCESS,
      });
      dispatch(fetchLawyerList());
      successMessage("Lawyer updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_LAWYER_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_LAWYER_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const deleteLawyer = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_LAWYER_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`super-admin/lawyer/destroy/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_LAWYER_SUCCESS,
      });
      dispatch(fetchLawyerList());
      successMessage("Lawyer Deleted successfully");
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_LAWYER_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_LAWYER_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

// ? Clients Actions
export const fetchClientList = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_CLIENTS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get("super-admin/client/list");
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_CLIENTS_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_CLIENTS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_CLIENTS_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_CLIENTS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const addClient = (values) => async (dispatch) => {
  dispatch({ type: adminConstants.ADD_CLIENT_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post("super-admin/client/create", values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.ADD_CLIENT_SUCCESS,
      });
      dispatch(fetchClientList());
      successMessage("Client added successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.ADD_CLIENT_FAILURE,
        payload: res?.data?.message,
      });
      errorMessage(res?.data?.message);
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.ADD_CLIENT_FAILURE,
      payload: error?.response?.data || "Server Error",
    });
    errorMessage("Internal server error");
  }
};

export const updateClient = (id, values) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_CLIENT_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`super-admin/client/update/${id}`, values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_CLIENT_SUCCESS,
      });
      dispatch(fetchClientList());
      successMessage("Client updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_CLIENT_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_CLIENT_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const deleteClient = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_CLIENT_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`super-admin/client/destroy/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_CLIENT_SUCCESS,
      });
      dispatch(fetchClientList());
      successMessage("Client Deleted successfully");
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_CLIENT_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_CLIENT_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const contractListForAdmin = (data) => async (dispatch) => {
  dispatch({ type: adminConstants.CONTRACT_LIST_REQUEST });
  attachToken();
  try {
    const res = await custAxios.post("super-admin/contracts/list", data);
    if (res?.data.status === "success") {
      dispatch({
        type: adminConstants.CONTRACT_LIST_SUCCESS,
        payload: res?.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.CONTRACT_LIST_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.CONTRACT_LIST_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const contractByIdForAdmin = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.CONTRACT_BY_ID_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/contracts/show/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.CONTRACT_BY_ID_SUCCESS,
        payload: res?.data?.contracts,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.CONTRACT_BY_ID_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.CONTRACT_BY_ID_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const allCountriesForAdmin = () => async (dispatch) => {
  dispatch({ type: adminConstants.ALL_COUNTRIES_FOR_ADMIN_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/all-countries`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.ALL_COUNTRIES_FOR_ADMIN_SUCCESS,
        payload: res?.data?.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.ALL_COUNTRIES_FOR_ADMIN_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.ALL_COUNTRIES_FOR_ADMIN_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const updateCountryStatusForAdmin = (id, data) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_COUNTRY_FOR_ADMIN_REQUEST });
  attachToken();
  try {
    const res = await custAxios.post(
      `super-admin/update-country-status/${id}`,
      data
    );
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_COUNTRY_FOR_ADMIN_SUCCESS,
        payload: res?.data?.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_COUNTRY_FOR_ADMIN_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_COUNTRY_FOR_ADMIN_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const webSetting = (data) => async (dispatch) => {
  dispatch({ type: adminConstants.WEB_SETTING_REQUEST });
  try {
    attachTokenWithFormAxios();
    const res = await formAxios.post(
      `super-admin/website-settings/update`,
      data
    );
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.WEB_SETTING_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
      successMessage(res?.data?.message);
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.WEB_SETTING_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.WEB_SETTING_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const webDetailsForAdmin = () => async (dispatch) => {
  dispatch({ type: adminConstants.WEB_DETAILS_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/website-settings/show`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.WEB_DETAILS_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.WEB_DETAILS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.WEB_DETAILS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const fetchWithdrawRequestListForAdmin = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_WITHDRAW_REQUEST_LIST_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/withdraw-request/list`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_WITHDRAW_REQUEST_LIST_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_WITHDRAW_REQUEST_LIST_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_WITHDRAW_REQUEST_LIST_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_WITHDRAW_REQUEST_LIST_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const updateWithdrawForAdmin = (id, data) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_REQUEST_REQUEST });
  attachToken();
  try {
    const res = await custAxios.post(
      `super-admin/withdraw-request/update/${id}`,
      data
    );
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_REQUEST_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch(fetchWithdrawRequestListForAdmin());
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_REQUEST_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_REQUEST_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const fetchFAQsList = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_FAQS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get("super-admin/faqs/list");
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_FAQS_SUCCESS,
        payload: res?.data.data,
      });

      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_FAQS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_FAQS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_FAQS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const detailsByFaqs = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DETAIL_BY_FAQS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`super-admin/faqs/details/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DETAIL_BY_FAQS_SUCCESS,
      });
      // dispatch(fetchFAQsList());
      // successMessage("Faqs updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DETAIL_BY_FAQS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DETAIL_BY_FAQS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const createFaqs = (values) => async (dispatch) => {
  dispatch({ type: adminConstants.CREATE_FAQS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`super-admin/faqs/create`, values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.CREATE_FAQS_SUCCESS,
      });
      dispatch(fetchFAQsList());
      // successMessage("Faqs updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.CREATE_FAQS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.CREATE_FAQS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const deleteFaqs = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_FAQS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`super-admin/faqs/delete/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_FAQS_SUCCESS,
      });
      dispatch(fetchFAQsList());
      successMessage("FAQ Deleted successfully");
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_FAQS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_FAQS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const updateFaqs = (id, values) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_FAQS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`super-admin/faqs/update/${id}`, values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_FAQS_SUCCESS,
      });
      dispatch(fetchFAQsList());
      successMessage("Faqs updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_FAQS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_FAQS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const reorderFaqs = (values) => async (dispatch) => {
  dispatch({ type: adminConstants.REORDER_FAQS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`super-admin/faqs/reorder`, values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.REORDER_FAQS_SUCCESS,
      });
      dispatch(fetchFAQsList());
      // successMessage("Faqs updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.REORDER_FAQS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.REORDER_FAQS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const createTags = (values) => async (dispatch) => {
  dispatch({ type: adminConstants.CREATE_TAGS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`super-admin/tags/create`, values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.CREATE_TAGS_SUCCESS,
      });
      dispatch(fetchTagsList());
      // successMessage("TAGS updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.CREATE_TAGS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.CREATE_TAGS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const deleteTags = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_TAGS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`super-admin/tags/delete/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_TAGS_SUCCESS,
      });
      dispatch(fetchTagsList());
      successMessage("FAQ Deleted successfully");
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_TAGS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_TAGS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const updateTags = (id, values) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_TAGS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`super-admin/tags/update/${id}`, values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_TAGS_SUCCESS,
      });
      dispatch(fetchTagsList());
      successMessage("TAGS updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_TAGS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_TAGS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const fetchTagsList = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_TAGS_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get("super-admin/tags/list");
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_TAGS_SUCCESS,
        payload: res?.data.data,
      });

      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_TAGS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_TAGS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_TAGS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const createCatagory = (values) => async (dispatch) => {
  dispatch({ type: adminConstants.CREATE_CATAGORY_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`super-admin/categories/create`, values);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.CREATE_CATAGORY_SUCCESS,
      });
      dispatch(fetchCatagoryList());
      // successMessage("CATAGORY updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.CREATE_CATAGORY_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.CREATE_CATAGORY_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const deleteCatagory = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_CATAGORY_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`super-admin/categories/delete/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_CATAGORY_SUCCESS,
      });
      dispatch(fetchCatagoryList());
      successMessage("Catagory Deleted successfully");
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_CATAGORY_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_CATAGORY_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const updateCatagory = (id, values) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_CATAGORY_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(
      `super-admin/categories/update/${id}`,
      values
    );
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_CATAGORY_SUCCESS,
      });
      dispatch(fetchCatagoryList());
      successMessage("Catagory updated successfully");
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_CATAGORY_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_CATAGORY_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const fetchCatagoryList = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_CATAGORY_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get("super-admin/categories/list");
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_CATAGORY_SUCCESS,
        payload: res?.data.data,
      });

      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_CATAGORY_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_CATAGORY_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_CATAGORY_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const listBlogs = () => async (dispatch) => {
  dispatch({ type: adminConstants.LIST_BLOGS_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/blogs/list`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.LIST_BLOGS_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.LIST_BLOGS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.LIST_BLOGS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.LIST_BLOGS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const getABlog = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.GET_A_BLOG_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/blogs/details/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.GET_A_BLOG_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.GET_A_BLOG_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.GET_A_BLOG_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const createBlog = (data) => async (dispatch) => {
  dispatch({ type: adminConstants.CREATE_BLOG_REQUEST });
  attachTokenWithFormAxios();
  try {
    const res = await formAxios.post(`super-admin/blogs/create`, data);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.CREATE_BLOG_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch(listBlogs());
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.CREATE_BLOG_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.CREATE_BLOG_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const updateBlog = (id, data) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_BLOG_REQUEST });
  attachTokenWithFormAxios();
  try {
    const res = await formAxios.post(`super-admin/blogs/update/${id}`, data);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_BLOG_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch(listBlogs());
      dispatch(getABlog(id));
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_BLOG_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_BLOG_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const deleteBlog = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_BLOG_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/blogs/delete/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_BLOG_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch(listBlogs());
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_BLOG_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_BLOG_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const fetchMessages = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_MESSAGES_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/contact/messages`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.FETCH_MESSAGES_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_MESSAGES_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_MESSAGES_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_MESSAGES_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    // errorMessage(error.response.data.message);
  }
};
export const respondMessage = (data) => async (dispatch) => {
  dispatch({ type: adminConstants.RESPOND_MESSAGE_REQUEST });
  attachToken();
  try {
    const res = await custAxios.post(`super-admin/contact/respond`, data);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.RESPOND_MESSAGE_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.RESPOND_MESSAGE_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.RESPOND_MESSAGE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const deleteMessage = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_MESSAGE_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/contact/message/${id}/delete`);
    if (res?.data?.res === "success") {
      dispatch({
        type: adminConstants.DELETE_MESSAGE_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_MESSAGE_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_MESSAGE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const fetchNewsLetter = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_NEWSLETTER_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/newsletters/subscribers`);
    if (res?.data.res === "success") {
      dispatch({
        type: adminConstants.FETCH_NEWSLETTER_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_NEWSLETTER_SUCCESS,
        payload: [],
      });
      dispatch({
        type: adminConstants.FETCH_NEWSLETTER_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_NEWSLETTER_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const fetchUserStatistics =
  (lawyerId, date = null) =>
  async (dispatch) => {
    dispatch({ type: adminConstants.FETCH_USERSTATISTICS_REQUEST });
    attachToken();
    try {
      // Aug 2023
      const res = await custAxios.get(
        `super-admin/user-stats/${lawyerId}${date ? `?date=${date}` : ``}`
      );
      if (res.data.res === "success") {
        dispatch({
          type: adminConstants.FETCH_USERSTATISTICS_SUCCESS,
          payload: res?.data?.data,
        });
        return res?.data;
      } else if (res?.data?.res === "error") {
        dispatch({
          type: adminConstants.FETCH_USERSTATISTICS_FAILURE,
          payload: res?.data?.message,
        });
        return "error";
      }
    } catch (error) {
      dispatch({
        type: adminConstants.FETCH_USERSTATISTICS_FAILURE,
        payload: error?.response?.data?.message || "Server Error",
      });
      errorMessage(error.response.data.message);
    }
  };
export const fetchCarousels = () => async (dispatch) => {
  dispatch({ type: adminConstants.FETCH_CAROUSELS_REQUEST });
  attachToken();
  try {
    // Aug 2023
    const res = await custAxios.get(`super-admin/carousel/fetch`);
    if (res.data.res === "success") {
      dispatch({
        type: adminConstants.FETCH_CAROUSELS_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.FETCH_CAROUSELS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.FETCH_CAROUSELS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const addAnImageOfCarousel = (data) => async (dispatch) => {
  dispatch({ type: adminConstants.ADD_AN_IMAGE_OF_CAROSEL_REQUEST });
  attachTokenWithFormAxios();
  try {
    // Aug 2023
    const res = await formAxios.post(`super-admin/carousel/add-image`, data);
    if (res.data.res === "success") {
      dispatch({
        type: adminConstants.ADD_AN_IMAGE_OF_CAROSEL_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.ADD_AN_IMAGE_OF_CAROSEL_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.ADD_AN_IMAGE_OF_CAROSEL_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const addCarouselText = (data) => async (dispatch) => {
  dispatch({ type: adminConstants.ADD_CAROUSEL_TEXT_REQUEST });
  attachToken();
  try {
    // Aug 2023
    const res = await custAxios.post(`super-admin/carousel/add-text`, data);
    if (res.data.res === "success") {
      dispatch({
        type: adminConstants.ADD_CAROUSEL_TEXT_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.ADD_CAROUSEL_TEXT_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.ADD_CAROUSEL_TEXT_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const updateCarouselText = (id, data) => async (dispatch) => {
  dispatch({ type: adminConstants.UPDATE_CAROUSEL_TEXT_REQUEST });
  attachToken();
  try {
    // Aug 2023
    const res = await custAxios.post(
      `super-admin/carousel/update-text/${id}`,
      data
    );
    if (res.data.res === "success") {
      dispatch({
        type: adminConstants.UPDATE_CAROUSEL_TEXT_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.UPDATE_CAROUSEL_TEXT_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.UPDATE_CAROUSEL_TEXT_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const reorderCarousel = (data) => async (dispatch) => {
  dispatch({ type: adminConstants.REORDER_CAROUSEL_REQUEST });
  attachToken();
  try {
    // Aug 2023
    const res = await custAxios.post(
      `super-admin/carousel/reorder-images`,
      data
    );
    if (res.data.res === "success") {
      dispatch({
        type: adminConstants.REORDER_CAROUSEL_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.REORDER_CAROUSEL_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.REORDER_CAROUSEL_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const deleteAnImageOfCarousel = (id) => async (dispatch) => {
  dispatch({ type: adminConstants.DELETE_AN_IMAGE_OF_CAROUSEL_REQUEST });
  attachToken();
  try {
    const res = await custAxios.get(`super-admin/carousel/delete-image/${id}`);
    if (res.data.res === "success") {
      dispatch({
        type: adminConstants.DELETE_AN_IMAGE_OF_CAROUSEL_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: adminConstants.DELETE_AN_IMAGE_OF_CAROUSEL_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: adminConstants.DELETE_AN_IMAGE_OF_CAROUSEL_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
