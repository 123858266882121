import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { technicianConstants } from "../constants/technicianConstants";

export const storeTechnician = (value) => async (dispatch) => {

    dispatch({ type: technicianConstants.STORE_TECHNICIAN_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/technician/store", value);
        if (res?.data?.status == "success") {

            dispatch({
                type: technicianConstants.STORE_TECHNICIAN_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(JSON.stringify(res?.data?.message))
            return res?.data;

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: technicianConstants.STORE_TECHNICIAN_FAILURE,
            });
            errorMessage(JSON.stringify(res?.data?.message))
            return res?.data;
        }
    } catch (error) {
        dispatch({
            type: technicianConstants.STORE_TECHNICIAN_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(JSON.stringify(error.response.data.message));
    }
};

export const fetchTechnician = () => async (dispatch) => {

    dispatch({ type: technicianConstants.FETCH_TECHNICIAN_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/technician/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: technicianConstants.FETCH_TECHNICIAN_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: technicianConstants.FETCH_TECHNICIAN_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: technicianConstants.FETCH_TECHNICIAN_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};

export const destroyTechnician = (id) => async (dispatch) => {

    dispatch({ type: technicianConstants.DESTROY_TECHNICIAN_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get(`/admin/technician/destroy/${id}`);

        if (res?.data?.status == "success") {
            dispatch({
                type: technicianConstants.DESTROY_TECHNICIAN_SUCCESS,
                payload: id,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: technicianConstants.DESTROY_TECHNICIAN_FAILURE,
                payload: res?.data?.message,
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: technicianConstants.DESTROY_TECHNICIAN_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(error.response.data.message);
    }
};  