import { DataTable } from "mantine-datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Text, TextInput } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from '@mantine/core';
import { fetchPrinterModel, storePrinterModel } from "../../../redux/actions/printerAction";
import { IconSearch } from "@tabler/icons-react";
import { destroyCustomer, fetchCustomer } from "../../../redux/actions/customerAction";
import { AiOutlineDelete } from "react-icons/ai";
import CustomerModal from "../../modal/customer/CustomerModal";
const CustomerListTable = ({ PAGE_SIZE = 30 }) => {
    const [data, setProductData] = useState([]);
    const [dataOfTable, setDataOfTable] = useState(data || []);
    const [page, setPage] = useState(1);
    const { loading, customerList } = useSelector((state) => state?.customer);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [records, setRecords] = useState(dataOfTable?.slice(0, PAGE_SIZE) || []);


    const [query, setQuery] = useState('');

    useEffect(() => {
        setDataOfTable(
            data?.filter(({ name }) => {
                return name?.toLowerCase()?.includes(query?.trim()?.toLowerCase());
            })
        );
    }, [query]);




    useEffect(() => {
        setDataOfTable(data);
    }, [data]);



    useEffect(() => {
        dispatch(fetchCustomer());
    }, []);

    useEffect(() => {
        setProductData(customerList)
    }, [customerList]);

    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(dataOfTable?.slice(from, to));
    }, [page, dataOfTable]);

    const handleDelete = async (id) => {
        await dispatch(destroyCustomer(id)).then((res) => {
            if (dataOfTable?.length !== 1 && records.length === 1) {
                setPage(page - 1);
            }
        });
    };



    const product_columns = [
        {
            accessor: "SNO",
            title: <Text>#</Text>,
            width: "auto",
            render: (record, i) => <Text>{i + 1}</Text>,
        },
        {
            accessor: "id",
            width: "auto",
            title: "Id",
        },
        {
            accessor: "name",
            width: "auto",
            title: "Customer",
            filter: (
                <TextInput
                    label="Customer"
                    placeholder="Search Customers..."
                    icon={<IconSearch size={16} />}
                    value={query}
                    onChange={(e) => setQuery(e.currentTarget.value)}
                />
            ),
        },

        {
            accessor: "actions",
            width: "auto",
            textAlignment: "right",
            title: <Text>Actions</Text>,
            render: (record) => (
                <div>
                    <Popover width={300} trapFocus position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <Button
                                className='fa-2x px-1'
                                size='sm'
                                variant="gradient"
                                gradient={{ from: 'rgba(235, 0, 0, 1)', to: 'rgba(255, 0, 106, 1)', deg: 59 }}>
                                <AiOutlineDelete />
                            </Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <div className='d-flex align-items-start flex-column'>
                                <p className='text-left'>Are you sure, want to delete</p>
                                <Button
                                    onClick={() => handleDelete(record.id)}
                                    variant="filled" color="red">
                                    Delete
                                </Button>
                            </div>
                        </Popover.Dropdown>
                    </Popover>
                </div>
            ),
        },
    ];

    const [OpenModal, setOpenModal] = useState(false)

    return (
        <div>
            <CustomerModal setModalPopup={setOpenModal} modalPopup={OpenModal} setCustomerData={setProductData} />

            <div className="d-flex mb-3 justify-content-between align-items-end">
                <h3 className="m-0" onClick={() => dispatch(fetchCustomer())}>Customer List</h3>
                <div className="d-flex gap-3">
                    <Button
                        className="text-uppercase"
                        variant="gradient"
                        gradient={{ from: 'lime', to: 'teal', deg: 90 }}
                        onClick={() => setOpenModal(true)}>
                        Add New Customer
                    </Button>
                    <Button
                        className="text-uppercase"
                        variant="gradient"
                        gradient={{ from: 'red', to: 'yellow', deg: 125 }}
                        onClick={() => navigate('/customer/bulk')}>
                        Add Bulk Customer
                    </Button>
                </div>
            </div>
            <DataTable
                className="data-table-with-actions"
                withBorder
                records={records}
                withColumnBorders
                striped
                highlightOnHover
                verticalSpacing={10}
                columns={
                    product_columns
                }
                totalRecords={dataOfTable?.length}
                recordsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={(p) => setPage(p)}
                fetching={loading}
                minHeight={400}
                loaderVariant="dots"
                noRecordsText="No records found"
                paginationText={({ from, to, totalRecords }) =>
                    `Records ${from} - ${to} of ${totalRecords}`
                }
                paginationSize="md"
            />
        </div>
    );
}

export default CustomerListTable