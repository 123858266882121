import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { webConstants } from "../constants/webContants";

export const fetchFeaturedLawyers = () => async (dispatch) => {
  dispatch({ type: webConstants.FETCH_FEATURED_LAWYER_REQUEST });
  try {
    const res = await custAxios.get("/website/featured-lawyers");
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.FETCH_FEATURED_LAWYER_SUCCESS,
        payload: res?.data.data,
      });
      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.FETCH_FEATURED_LAWYER_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.FETCH_FEATURED_LAWYER_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: webConstants.FETCH_FEATURED_LAWYER_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const getLawyerById = (id) => async (dispatch) => {
  dispatch({ type: webConstants.GET_LAWYER_BY_ID_REQUEST });
  try {
    const res = await custAxios.get(`website/lawyer/${id}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.GET_LAWYER_BY_ID_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.GET_LAWYER_BY_ID_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: webConstants.GET_LAWYER_BY_ID_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const findLawyers = (data) => async (dispatch) => {
  dispatch({ type: webConstants.FIND_LAWYER_REQUEST });
  try {
    const res = await custAxios.get(`/website/find-lawyer${data}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.FIND_LAWYER_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.FIND_LAWYER_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.FIND_LAWYER_FAILURE,
        payload: res?.data?.message || "Server Error",
      });
      errorMessage(res?.data?.message);
    }
  } catch (error) {
    dispatch({
      type: webConstants.FIND_LAWYER_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error?.response?.data?.message);
  }
};

export const fetchPracticeList = () => async (dispatch) => {
  dispatch({ type: webConstants.FETCH_PRACTICE_REQUEST });
  try {
    const res = await custAxios.get("super-admin/area-Practice/list");
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.FETCH_PRACTICE_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.FETCH_PRACTICE_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.FETCH_PRACTICE_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.FETCH_PRACTICE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const getJurisdictionByCountry = (country) => async (dispatch) => {
  dispatch({ type: webConstants.GET_JURISDICTION_BY_COUNTRY_REQUEST });
  try {
    const res = await custAxios.get(
      `website/jurisdiction?country_id=${country}`
    );
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.GET_JURISDICTION_BY_COUNTRY_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.GET_JURISDICTION_BY_COUNTRY_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.GET_JURISDICTION_BY_COUNTRY_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.GET_JURISDICTION_BY_COUNTRY_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const getCountriesOfJurisdictions = () => async (dispatch) => {
  dispatch({ type: webConstants.GET_COUNTRIES_OF_JURISDICTIONS_REQUEST });
  try {
    const res = await custAxios.get(`website/countries`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.GET_COUNTRIES_OF_JURISDICTIONS_SUCCESS,
        payload: res?.data.data || [],
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.GET_COUNTRIES_OF_JURISDICTIONS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.GET_COUNTRIES_OF_JURISDICTIONS_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.GET_COUNTRIES_OF_JURISDICTIONS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const fetchWebDetails = () => async (dispatch) => {
  dispatch({ type: webConstants.FETCH_WEB_DETAILS_REQUEST });
  try {
    const res = await custAxios.get(`website/website-details`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.FETCH_WEB_DETAILS_SUCCESS,
        payload: res?.data.data,
      });
      localStorage.setItem("webDetails", JSON.stringify(res?.data.data));
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.FETCH_WEB_DETAILS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.FETCH_WEB_DETAILS_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.FETCH_WEB_DETAILS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const fetchBlogsList =
  (category = null, tags = null) =>
  async (dispatch) => {
    dispatch({ type: webConstants.BLOGS_LIST_REQUEST });

    let url = "blogs/list";

    if (category !== null || (tags !== null && tags.length > 0)) {
      url += "?";

      if (category !== null) {
        url += `category_id=${category}`;
      }

      if (tags !== null && tags.length > 0) {
        if (category !== null) {
          url += "&";
        }

        url += `tags[]=${tags.join("&tags[]=")}`;
      }
    }

    try {
      const res = await custAxios.get(url);

      if (res?.data?.res === "success") {
        dispatch({
          type: webConstants.BLOGS_LIST_SUCCESS,
          payload: res?.data.data,
        });
        return res?.data;
      } else if (res?.data?.res === "error") {
        dispatch({
          type: webConstants.BLOGS_LIST_SUCCESS,
          payload: [],
        });
        dispatch({
          type: webConstants.BLOGS_LIST_FAILURE,
          payload: res?.data.message,
        });
        return res?.data;
      }
    } catch (error) {
      dispatch({
        type: webConstants.BLOGS_LIST_FAILURE,
        payload: error?.response?.data?.message || "Server Error",
      });
      errorMessage(error.response.data.message);
    }
  };

export const fetchBlogBySlug = (slug) => async (dispatch) => {
  dispatch({ type: webConstants.GET_BLOG_BY_SLUG_REQUEST });
  try {
    const res = await custAxios.get(`blog/${slug}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.GET_BLOG_BY_SLUG_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.GET_BLOG_BY_SLUG_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.GET_BLOG_BY_SLUG_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.GET_BLOG_BY_SLUG_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const fetchBlogComments = (id) => async (dispatch) => {
  dispatch({ type: webConstants.GET_BLOG_COMMENTS_REQUEST });
  try {
    const res = await custAxios.get(`blog/${id}/comments`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.GET_BLOG_COMMENTS_SUCCESS,
        payload: res?.data.data,
      });
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.GET_BLOG_COMMENTS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.GET_BLOG_COMMENTS_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.GET_BLOG_COMMENTS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const likeABlog = (id) => async (dispatch) => {
  dispatch({ type: webConstants.LIKE_BLOG_REQUEST });
  attachToken();
  try {
    const res = await custAxios.post(`blog/${id}/like`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.LIKE_BLOG_SUCCESS,
        payload: res?.data.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.LIKE_BLOG_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.LIKE_BLOG_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.LIKE_BLOG_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const commentABlog = (id, content) => async (dispatch) => {
  dispatch({ type: webConstants.COMMENT_BLOG_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`blog/${id}/comment`, content);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.COMMENT_BLOG_SUCCESS,
        payload: res?.data.data,
      });
      dispatch(fetchBlogComments(id));
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.COMMENT_BLOG_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.COMMENT_BLOG_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.COMMENT_BLOG_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const editCommentOfBlog = (id, data) => async (dispatch) => {
  dispatch({ type: webConstants.EDIT_COMMENT_OF_BLOG_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`comment/${id}/edit`, data);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.EDIT_COMMENT_OF_BLOG_SUCCESS,
        payload: res?.data.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.EDIT_COMMENT_OF_BLOG_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.EDIT_COMMENT_OF_BLOG_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.EDIT_COMMENT_OF_BLOG_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const deleteCommentOfBlog = (id) => async (dispatch) => {
  dispatch({ type: webConstants.DELETE_COMMENT_OF_BLOG_REQUEST });
  try {
    attachToken();
    const res = await custAxios.get(`comment/${id}/delete`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.DELETE_COMMENT_OF_BLOG_SUCCESS,
        payload: res?.data.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.DELETE_COMMENT_OF_BLOG_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.DELETE_COMMENT_OF_BLOG_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.DELETE_COMMENT_OF_BLOG_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const replyOfComment = (id, content) => async (dispatch) => {
  dispatch({ type: webConstants.REPLY_OF_COMMENT_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`comment/${id}/reply`, content);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.REPLY_OF_COMMENT_SUCCESS,
        payload: res?.data.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.REPLY_OF_COMMENT_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.REPLY_OF_COMMENT_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.REPLY_OF_COMMENT_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const editReplyOfComment = (id, content) => async (dispatch) => {
  dispatch({ type: webConstants.REPLY_OF_COMMENT_REQUEST });
  try {
    attachToken();
    const res = await custAxios.post(`reply/${id}/edit`, content);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.REPLY_OF_COMMENT_SUCCESS,
        payload: res?.data.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.REPLY_OF_COMMENT_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.REPLY_OF_COMMENT_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.REPLY_OF_COMMENT_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const deleteReplyOfComment = (id) => async (dispatch) => {
  dispatch({ type: webConstants.REPLY_OF_COMMENT_REQUEST });
  try {
    const res = await custAxios.post(`reply/${id}/delete`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.REPLY_OF_COMMENT_SUCCESS,
        payload: res?.data.data,
      });

      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.REPLY_OF_COMMENT_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.REPLY_OF_COMMENT_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.REPLY_OF_COMMENT_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const fetchBlogsCategories = () => async (dispatch) => {
  dispatch({ type: webConstants.BLOGS_CATEGORIES_REQUEST });
  try {
    const res = await custAxios.get(`website/blog-categories`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.BLOGS_CATEGORIES_SUCCESS,
        payload: res?.data.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.BLOGS_CATEGORIES_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.BLOGS_CATEGORIES_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.BLOGS_CATEGORIES_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const subscribe = (data) => async (dispatch) => {
  dispatch({ type: webConstants.SUBSCRIBE_REQUEST });
  try {
    const res = await custAxios.post(`newsletters/subscribe`, data);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.SUBSCRIBE_SUCCESS,
        payload: res?.data.data,
      });
      successMessage("Subscribe Successfully");
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.SUBSCRIBE_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.SUBSCRIBE_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.SUBSCRIBE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const unSubscribe = (email) => async (dispatch) => {
  dispatch({ type: webConstants.UNSUBSCRIBE_REQUEST });
  try {
    const res = await custAxios.get(`newsletters/unsubscribe/${email}`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.UNSUBSCRIBE_SUCCESS,
        payload: res?.data.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.UNSUBSCRIBE_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.UNSUBSCRIBE_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.UNSUBSCRIBE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const fetchTags = () => async (dispatch) => {
  dispatch({ type: webConstants.TAGS_REQUEST });
  try {
    const res = await custAxios.get(`website/tags`);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.TAGS_SUCCESS,
        payload: res?.data.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.TAGS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.TAGS_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.TAGS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const postMessageOfContactUs = (data) => async (dispatch) => {
  dispatch({ type: webConstants.POST_A_MESSAGE_REQUEST });
  try {
    const res = await custAxios.post(`contact/send-email`, data);
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.POST_A_MESSAGE_SUCCESS,
        payload: res?.data.data,
      });
      return res?.data;
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.POST_A_MESSAGE_SUCCESS,
        payload: [],
      });
      dispatch({
        type: webConstants.POST_A_MESSAGE_FAILURE,
        payload: res?.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: webConstants.POST_A_MESSAGE_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const fetchFAQsListForWeb = () => async (dispatch) => {
  // console.log(values);
  dispatch({ type: webConstants.FETCH_FAQS_REQUEST });
  try {
    const res = await custAxios.get("website/faqs");
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.FETCH_FAQS_SUCCESS,
        payload: res?.data.data,
      });

      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.FETCH_FAQS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: webConstants.FETCH_FAQS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const fetchCarouselsForHomePage = () => async (dispatch) => {
  // console.log(values);
  dispatch({ type: webConstants.FETCH_CAROUSELS_REQUEST });
  try {
    const res = await custAxios.get("website/carousel");
    if (res?.data?.res === "success") {
      dispatch({
        type: webConstants.FETCH_CAROUSELS_SUCCESS,
        payload: res?.data.data,
      });

      return "success";
    } else if (res?.data?.res === "error") {
      dispatch({
        type: webConstants.FETCH_CAROUSELS_FAILURE,
        payload: res?.data?.message,
      });
      return "error";
    }
  } catch (error) {
    dispatch({
      type: webConstants.FETCH_CAROUSELS_FAILURE,
      payload: error?.response?.data?.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
