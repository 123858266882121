import React, { useState } from 'react'
import { storeBulkCustomers } from '../../../redux/actions/customerAction';
import { Button, Modal, TextInput } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../../globalFunctions';

const CustomerModal = ({ setCustomerData, setModalPopup, modalPopup }) => {

 
    const [Name, setName] = useState('') 
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
 

    const HandleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (Name.trim() == '') {
                return errorMessage('Enter the customer name')
            }
            const formdata = new FormData()
            formdata.append('name', Name)
            await dispatch(storeBulkCustomers(formdata, 'store')).then((res) => {

                if (res.status == 'success') {
                    setCustomerData((Prevalue) => [res.data, ...Prevalue])
                    setName('')
                    setModalPopup(false);
                }
            })
        } catch (error) {

        }
        setLoading(false)
    }
    return (
        <Modal opened={modalPopup} onClose={() => setModalPopup(false)} title="Add Customer" centered>

            <form onSubmit={HandleSubmit}>
                <TextInput
                    value={Name}
                    name='name'
                    onChange={(e) => setName(e.target.value)}
                    label="Customer Name"
                    className='mt-3'
                    placeholder="Customer Name"
                    requried
                    withAsterisk

                />


                <Button
                    className='mt-5'
                    variant="gradient"
                    type='submit'
                    disabled={isLoading}
                    gradient={{ from: 'teal', to: 'green', deg: 172 }}
                >
                    Add Customer
                </Button>
            </form>
        </Modal>
    )
}

export default CustomerModal