
export const regionConstants = {
    FETCH_REGION_REQUEST: "FETCH_REGION_REQUEST",
    FETCH_REGION_SUCCESS: "FETCH_REGION_SUCCESS",
    FETCH_REGION_FAILURE: "FETCH_REGION_FAILURE",

    STORE_REGION_REQUEST: "STORE_REGION_REQUEST",
    STORE_REGION_SUCCESS: "STORE_REGION_SUCCESS",
    STORE_REGION_FAILURE: "STORE_REGION_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
