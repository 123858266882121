import { Select, TextInput, Button } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { fetchUnits } from '../../redux/actions/unitAction'
import { fetchBrands } from '../../redux/actions/brandsAction'
import { fetchProductCategory } from '../../redux/actions/productCategoryAction'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Fade } from 'react-reveal'
import { FaPlus } from 'react-icons/fa'
import { useDisclosure } from '@mantine/hooks'
import { storeProduct } from '../../redux/actions/productAction'
import slugify from 'react-slugify'
import AddBrandModal from '../../components/modal/AddBrand'
import { FetchModelByBrand, storePrinter } from '../../redux/actions/printerAction'
import PrinterModal from '../../components/modal/printer/model'
import { fetchRegion } from '../../redux/actions/regionAction'
import { fetchRegionLocation } from '../../redux/actions/locationAction'
import { fetchDepartment } from '../../redux/actions/departmentAction'
import { fetchCustomer } from '../../redux/actions/customerAction'
import PrinterDetailModal from '../../components/modal/printer/PrinterDetail'

const AddPrinterPage = () => {
    const { brand } = useSelector((state) => state)
    const { regionList } = useSelector((state) => state?.region)
    const { PrinterByBrands } = useSelector((state) => state?.printers);
    const { loading, locationsByRegion } = useSelector((state) => state?.location);
    const { departmentList } = useSelector((state) => state?.department);
    const { customerList } = useSelector((state) => state?.customer);
    const dispatch = useDispatch();
    const [brands, setBrands] = useState([])
    const [brandPopup, setBrandPopup] = useState(false);
    const [modelPopup, setModelPopup] = useState(false);
    const [printerModel, setPrinterModel] = useState([])
    const [regions, setRegions] = useState([])
    const [locations, setLocations] = useState([])
    const [departments, setDepartments] = useState([])
    const [customers, setCustomers] = useState([])
    const [isLoading, setLoading] = useState(false);
    const [printerData, setPrinterData] = useState(null);
    useEffect(() => {
        const brandNames = brand.brandsList.map((item) => ({ value: item.id, label: item.name }));
        setBrands(brandNames);

    }, [brand]);

    useEffect(() => {
        const brandNames = PrinterByBrands.map((item) => ({ value: item.id, label: item.name }));
        setPrinterModel(brandNames);
    }, [PrinterByBrands]);

    useEffect(() => {
        const names = regionList.map((item) => ({ value: item.id, label: item.name }));
        setRegions(names);
    }, [regionList]);

    useEffect(() => {
        const names = locationsByRegion.map((item) => ({ value: item.id, label: item.name }));
        setLocations(names);
    }, [locationsByRegion]);

    useEffect(() => {
        const names = departmentList.map((item) => ({ value: item.id, label: item.name }));
        setDepartments(names);
    }, [departmentList]);

    useEffect(() => {
        const names = customerList.map((item) => ({ value: item.id, label: item.name }));
        setCustomers(names);
    }, [customerList]);


    useEffect(() => {
        dispatch(fetchDepartment())
        dispatch(fetchRegion());
        dispatch(fetchBrands());
        dispatch(fetchCustomer());
    }, [])

    const initialValue = {
        name: '',
        serial_number: '',
        brand: '1',
        model: '',
        region: 0,
        location: 0,
        department: 0,
        counter: 0,

    }


    const [inputValue, setInputValue] = useState(initialValue)
    const [printerDetailModal, setPrinterDetailModal] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            dispatch(storePrinter(inputValue)).then((res) => {
                if (res.status == 'success') {
                    setPrinterData(res.data);
                    setInputValue(initialValue)
                    setPrinterDetailModal(true)
                }
            })
        } catch (error) {

        }
        setLoading(false)
    }


    const fetchModeratorList = async (id) => {
        inputValue.model = '';
        setLoading(true)
        await dispatch(FetchModelByBrand(id))
        setLoading(false)
    }

    const fetchLocationList = async (id) => {
        inputValue.location = '';
        setLoading(true)
        setLocations([]);
        await dispatch(fetchRegionLocation(id))
        setLoading(false)
    }

    const handleInput = (value, name) => {

        if (name == 'brand') {

            fetchModeratorList(value)
        }

        if (name == 'region') {

            fetchLocationList(value)
        }

        const updatedInputValue = {
            ...inputValue,
            [name]: value
        };


        setInputValue(updatedInputValue);
    };




    return (
        <Fade>
            <div className='products'>
                <AddBrandModal setBrands={setBrands} BrandModel={brandPopup} setBrandModel={setBrandPopup} />
                <PrinterDetailModal data={printerData} modelPopup={printerDetailModal} setModelPopup={setPrinterDetailModal} />
                <PrinterModal
                    brandsData={brands}
                    modelPopup={modelPopup}
                    setModelPopup={setModelPopup}
                    setProductData={setPrinterModel}
                />

                <h3>Add Printer</h3>
                <form onSubmit={handleSubmit}>
                    <Select
                        onChange={(e) => handleInput(e, 'region')}
                        value={inputValue.region}
                        label="Regions"
                        name='regions'
                        placeholder="Please Select"
                        data={regions}
                        disabled={loading}
                        searchable
                        className='mt-3 w-100  required-input'
                    />
                    <div className='d-flex align-items-end w-100 gap-3'>
                        <Select
                            onChange={(e) => handleInput(e, 'brand')}

                            label="Brand"
                            name='brand'
                            placeholder="Please Select"
                            data={brands}
                            searchable
                            className='mt-3 w-100  required-input'
                        />
                        <Button
                            type='button'
                            onClick={() => setBrandPopup(true)}
                            variant="gradient"
                            gradient={{ from: 'lime', to: 'cyan', deg: 233 }}
                        >
                            <FaPlus />
                        </Button>
                    </div>


                    <TextInput value={inputValue.name} name='name' onChange={(e) => handleInput(e.target.value, e.target.name)} label="Printer Name" className='mt-3 required-input' placeholder="Printer Name" requried />

                    <TextInput value={inputValue.serial_number} name='serial_number' onChange={(e) => handleInput(e.target.value, e.target.name)} label="Serial Number" className='mt-3 required-input' placeholder="Serial Number" requried />
                    <div className='d-flex align-items-end w-100 gap-3'>
                        <Select
                            onChange={(e) => handleInput(e, 'model')}
                            value={inputValue.model}
                            label="Models"
                            name='Models'
                            placeholder="Please Select"
                            data={printerModel}
                            searchable
                            className='mt-3 w-100  required-input'
                        />
                        <Button
                            type='button'
                            onClick={() => setModelPopup(true)}
                            variant="gradient"
                            gradient={{ from: 'lime', to: 'cyan', deg: 233 }}
                        >
                            <FaPlus />
                        </Button>
                    </div>
                    <Select
                        onChange={(e) => handleInput(e, 'customer')}
                        value={inputValue.customer}
                        label="Customers"
                        name='customer'
                        placeholder="Please Select"
                        data={customers}
                        searchable
                        className='mt-3 w-100  required-input'
                    />
                    <Select
                        onChange={(e) => handleInput(e, 'location')}
                        value={inputValue.location}
                        label="Locations"
                        name='location'
                        placeholder="Please Select"
                        data={locations}
                        disabled={loading}
                        searchable
                        className='mt-3 w-100  required-input'
                    />

                    <Select
                        onChange={(e) => handleInput(e, 'department')}
                        value={inputValue.department}
                        label="Departments"
                        name='department'
                        placeholder="Please Select"
                        data={departments}
                        searchable
                        className='mt-3 w-100  required-input'
                    />



                    <TextInput
                        onChange={(e) => handleInput(e.target.value, e.target.name)}
                        value={inputValue.counter}
                        name='counter'
                        label="Current Counter"
                        placeholder="Current Counter"
                        className='mt-3 required-input'
                        requried
                    />

                    <Button
                        className='mt-5'
                        variant="gradient"
                        type='submit'
                        disabled={isLoading}
                        gradient={{ from: 'teal', to: 'green', deg: 172 }}
                    >
                        Add Printer
                    </Button>
                </form>

            </div>
        </Fade>
    )
}

export default AddPrinterPage