import { Box, Button, Group, PasswordInput, SegmentedControl, Select, TextInput } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom'
import { IconX, IconCheck } from '@tabler/icons-react';
import { Progress, Text, Popover, rem } from '@mantine/core';
import { fetchRegion } from '../../redux/actions/regionAction'
import { useSelector } from 'react-redux'
import { storeRequester } from '../../redux/actions/requeterAction'
import { errorMessage } from '../../globalFunctions'
import { storeTechnician } from '../../redux/actions/technicianAction'

function PasswordRequirement({ meets, label }) {
    return (
        <Text
            c={meets ? 'teal' : 'red'}
            style={{ display: 'flex', alignItems: 'center' }}
            mt={7}
            size="sm"
        >
            {meets ? (
                <IconCheck style={{ width: rem(14), height: rem(14) }} />
            ) : (
                <IconX style={{ width: rem(14), height: rem(14) }} />
            )}{' '}
            <Box ml={10}>{label}</Box>
        </Text>
    );
}

const requirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[a-z]/, label: 'Includes lowercase letter' },
    { re: /[A-Z]/, label: 'Includes uppercase letter' },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

function getStrength(password) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
        if (!requirement.re.test(password)) {
            multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const initialValue = {
    first_name: '',
    last_name: '',
    email: '',
    role_id: '2',
    region: '',
    phone: '',
    status: '1',
    password: '',

}

const AddTechnicianPage = () => {
    const [inputValue, setInputValue] = useState(initialValue)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { regionList } = useSelector((state) => state?.region)

    const [regions, setRegions] = useState([])
    const [isLoading, setLoading] = useState(false);
    const [popoverOpened, setPopoverOpened] = useState(false);
    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(inputValue.password)} />
    ));

    const strength = getStrength(inputValue.password);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';


    useEffect(() => {
        const names = regionList.map((item) => ({ value: item.id, label: item.name }));
        setRegions(names);
    }, [regionList]);


    useEffect(() => {
        dispatch(fetchRegion());
    }, [])


    const handleInput = (value, name) => {
        const updatedInputValue = {
            ...inputValue,
            [name]: value
        };

        setInputValue(updatedInputValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            inputValue.first_name == '' ||
            inputValue.last_name == '' ||
            inputValue.email == '' ||
            inputValue.password == '' ||
            inputValue.region == '' ||
            inputValue.phone == ''
        ) {
            return errorMessage("Fill all the fields");

        }


        setLoading(true)
        await dispatch(storeTechnician(inputValue)).then((res) => {
            if (res.data.status == 'success') {
                setInputValue(initialValue);
            }
        });
        setLoading(false)
    }

    return (
        <Fade>
            <div className="RequesterPage mt-5">
                <div className="d-flex mb-3 justify-content-between align-items-end">
                    <h3>Add Technician</h3>
                    <Button
                        className="text-uppercase"
                        variant="gradient"
                        size='sm'
                        gradient={{ from: 'rgba(5, 37, 92, 1)', to: 'rgba(0, 170, 255, 1)', deg: 172 }}
                        onClick={() => navigate('/technician/list')}>
                        Technicians List
                    </Button>
                </div>
                <form onSubmit={handleSubmit}>
                    <Box className='row gap- w-100'>

                        <TextInput
                            value={inputValue.first_name}
                            onChange={(e) => handleInput(e.target.value, 'first_name')}
                            label="First Name"
                            className='mt-3 col-md-6'
                            placeholder="First Name"
                            requried />

                        <TextInput
                            value={inputValue.last_name}
                            onChange={(e) => handleInput(e.target.value, 'last_name')}
                            label="Last Name"
                            className='mt-3 col-md-6'
                            placeholder="Last Name"
                            requried />

                        <TextInput
                            value={inputValue.email}
                            onChange={(e) => handleInput(e.target.value, 'email')}
                            label="Email Address"
                            className='mt-3 col-md-6'
                            placeholder="Email Address"
                            requried />

                        <Popover opened={popoverOpened} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
                            <Popover.Target>
                                <div
                                    className='col-md-6 mt-3 '
                                    onFocusCapture={() => setPopoverOpened(true)}
                                    onBlurCapture={() => setPopoverOpened(false)}
                                >
                                    <PasswordInput
                                        withAsterisk
                                        autoFocus={false}
                                        autoComplete={false}
                                        label="Password"
                                        placeholder="Password"
                                        value={inputValue.password}
                                        onChange={(event) => handleInput(event.currentTarget.value, 'password')}
                                    />
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <Progress color={color} value={strength} size={5} mb="xs" />
                                <PasswordRequirement label="Includes at least 6 characters" meets={inputValue.password.length > 5} />
                                {checks}
                            </Popover.Dropdown>
                        </Popover>
                        <Select
                            onChange={(e) => handleInput(e, 'region')}
                            value={inputValue.region}
                            label="Regions"
                            name='regions'
                            placeholder="Please Select"
                            data={regions}
                            searchable
                            className='mt-3 col-md-6  required-input'
                        />
                        <TextInput
                            value={inputValue.phone}
                            onChange={(e) => handleInput(e.target.value, 'phone')}
                            label="Phone Number"
                            className='mt-3 col-md-6'
                            placeholder="Phone Number"
                            requried />

                        <Box className='mt-3 col-md-6'>
                            <span className='d-block fw-600'>Account Status</span>

                            <SegmentedControl
                                value={inputValue.status}
                                className='w-100'
                                onChange={(e) => handleInput(e, 'status')}
                                color={inputValue.status == '1' ? 'blue' : 'red'}

                                data={[
                                    { label: 'Active', value: '1' },
                                    { label: 'In-Active', value: '0' }
                                ]}
                            />
                        </Box>
                        <div className='col-md-6'></div>
                        <Button
                            className='mt-5 w-auto'
                            variant="gradient"
                            type='submit'
                            disabled={isLoading}
                            gradient={{ from: 'teal', to: 'green', deg: 172 }}
                        >
                            Add Technician
                        </Button>
                    </Box>

                </form>
            </div>
        </Fade>
    )
}

export default AddTechnicianPage