import React from 'react'
import ModelListTable from '../../components/dataTables/printers/modelListTable'
import { Fade } from 'react-reveal'

const ModelListPage = () => {
    return (
        <Fade> 
            <ModelListTable />
        </Fade>
    )
}

export default ModelListPage