import React from 'react'
import ComplaintsListTable from '../../components/dataTables/complaints/ComplaintsListTable'

const ComplaintsListPage = () => {
    return (
        <div>
            <ComplaintsListTable />
        </div>
    )
}

export default ComplaintsListPage