import React, { useEffect, useState } from 'react'
import { Modal, Button, TextInput, Select } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../../globalFunctions';
import { storePrinterModel } from '../../../redux/actions/printerAction';

const PrinterModal = ({ setProductData, setModelPopup, modelPopup, brandsData }) => {
    const [Name, setName] = useState('')
    const [brand, setBrand] = useState()

    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);

     

    const HandleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (Name.trim() == '') {
                return errorMessage('Enter the unit name')
            }
            await dispatch(storePrinterModel({ name: Name, brand: brand })).then((res) => {

                if (res.status == 'success') {
                    setProductData((Prevalue) => [{ value: res.data.id, label: res.data.name }, ...Prevalue])
                    setName('')
                    setBrand('')
                    setModelPopup(false);
                }
            })
        } catch (error) {

        }
        setLoading(false)
    }

    return (
        <div>
            <Modal opened={modelPopup} onClose={() => setModelPopup(false)} title="Add Printer Model">
                <form onSubmit={HandleSubmit}>
                    <Select
                        onChange={(e) => setBrand(e)}
                        label="Brand"
                        name='brand'
                        placeholder="Please Select"
                        data={brandsData}
                        searchable
                        className='mt-3 w-100  required-input'
                    />

                    <TextInput value={Name} name='name' onChange={(e) => setName(e.target.value)} label="Model Name" className='mt-3 required-input' placeholder="Model Name" requried />

                    <Button
                        className='mt-5'
                        variant="gradient"
                        disabled={isLoading}
                        type='submit'
                        gradient={{ from: 'teal', to: 'green', deg: 172 }}
                    >
                        Add Model
                    </Button>
                </form>
            </Modal>

        </div>
    )
}

export default PrinterModal