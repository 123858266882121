import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FetchPrinterById } from '../../redux/actions/printerAction'
import { useSelector } from 'react-redux'
import CounterLogsListTable from '../../components/dataTables/printers/counterLogsListTable'

const ViewPrinterPage = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const { loading, showPrinter } = useSelector((state) => state?.printers);
    useEffect(() => {
        dispatch(FetchPrinterById(id))
    }, [id])


    return (
        <div className='mt-3 printer-detail'>
            <h2 className='mt-3 text-center'>Printer Detail</h2>
            <div className='row mb-5 bg-grey pt-2 p-md-4 p-lg-4 rounded-2'>
                <div className='col-md-3'>
                    <img className='w-100' src={showPrinter.qrcodes} />
                </div>
                <div className='col-md-4'>
                    <div className='detailGroupItems'>
                        <label>Id</label>
                        <span>{showPrinter.id}</span>
                    </div>
                    <div className='detailGroupItems'>
                        <label>Printer Name</label>
                        <span>{showPrinter.name}</span>
                    </div>
                    <div className='detailGroupItems'>
                        <label>Serial Number</label>
                        <span>{showPrinter.serial_number}</span>
                    </div>

                    <div className='detailGroupItems'>
                        <label>Counter</label>
                        <span>{showPrinter.counter}</span>
                    </div>
                </div>
                <div className='col-md-4'>

                    <div className='detailGroupItems'>
                        <label>Region</label>
                        <span>{showPrinter.region}</span>
                    </div>
                    <div className='detailGroupItems'>
                        <label>Customer</label>
                        <span>{showPrinter.customer}</span>
                    </div>
                    <div className='detailGroupItems'>
                        <label>Location</label>
                        <span>{showPrinter.location}</span>
                    </div>
                    <div className='detailGroupItems'>
                        <label>Department</label>
                        <span>{showPrinter.department}</span>
                    </div>
                </div>
            </div>

            <h2 className='mt-3 text-center'>Printer Maintenance</h2>
            <CounterLogsListTable id={id} />
        </div>
    )
}

export default ViewPrinterPage