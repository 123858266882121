import React from 'react'
import { Fade } from 'react-reveal'
import LocationsListTable from '../../components/dataTables/locations/LocationsListTable'

const ListLocationsPage = () => {
    return (
        <Fade>
            <LocationsListTable />
        </Fade>
    )
}

export default ListLocationsPage