import custAxios, { attachToken } from "../../services/axiosConfig";
import { errorMessage, successMessage } from "../../globalFunctions";
import { productConstants } from "../constants/productConstants";

export const storeProduct = (value) => async (dispatch) => {

    dispatch({ type: productConstants.STORE_PRODUCT_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/products/store", value);
        if (res?.data?.status == "success") {
            console.log('redux ', res?.data.data);
            dispatch({
                type: productConstants.STORE_PRODUCT_SUCCESS,
                payload: res?.data.data,
            });
            successMessage(JSON.stringify(res?.data?.message))
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: productConstants.STORE_PRODUCT_FAILURE,
            });
            errorMessage(JSON.stringify(res?.data?.message))
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: productConstants.STORE_PRODUCT_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(JSON.stringify(error.response.data.message));
    }
};
export const fetchProduct = () => async (dispatch) => {

    dispatch({ type: productConstants.FETCH_PRODUCT_REQUEST });
    try {
        attachToken();
        const res = await custAxios.get("/admin/products/list");

        if (res?.data?.status == "success") {
            dispatch({
                type: productConstants.FETCH_PRODUCT_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: productConstants.FETCH_PRODUCT_FAILURE,
                payload: (res?.data?.message),
            });
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: productConstants.FETCH_PRODUCT_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(JSON.stringify(error.response.data.message));
    }
};

export const searchProduct = (data) => async (dispatch) => {

    dispatch({ type: productConstants.SEARCH_PRODUCT_REQUEST });
    try {
        attachToken();
        const res = await custAxios.post("/admin/products/search-product", data);

        if (res?.data?.status == "success") {
            dispatch({
                type: productConstants.SEARCH_PRODUCT_SUCCESS,
                payload: res?.data.data,
            });
            return "success";

        } else if (res?.data?.status == "warning") {

            dispatch({
                type: productConstants.SEARCH_PRODUCT_FAILURE,
            });
            errorMessage(JSON.stringify(res?.data?.message));
            return "warning";
        }
    } catch (error) {
        dispatch({
            type: productConstants.SEARCH_PRODUCT_FAILURE,
            payload: error?.response?.data?.message || "Server Error",
        });
        errorMessage(JSON.stringify(error.response.data.message));
    }
};  