export const userConstants = {
  FETCH_PERSONAL_INFO_REQUEST: "FETCH_PERSONAL_INFO_REQUEST",
  FETCH_PERSONAL_INFO_SUCCESS: "FETCH_PERSONAL_INFO_SUCCESS",
  FETCH_PERSONAL_INFO_FAILURE: "FETCH_PERSONAL_INFO_FAILURE",

  UPDATE_PERSONAL_INFO_REQUEST: "UPDATE_PERSONAL_INFO_REQUEST",
  UPDATE_PERSONAL_INFO_SUCCESS: "UPDATE_PERSONAL_INFO_SUCCESS",
  UPDATE_PERSONAL_INFO_FAILURE: "UPDATE_PERSONAL_INFO_FAILURE",

  UPDATE_PROFILE_PICTURE_REQUEST: "UPDATE_PROFILE_PICTURE_REQUEST",
  UPDATE_PROFILE_PICTURE_SUCCESS: "UPDATE_PROFILE_PICTURE_SUCCESS",
  UPDATE_PROFILE_PICTURE_FAILURE: "UPDATE_PROFILE_PICTURE_FAILURE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};
