import React, { useState } from 'react'
import { Modal, Button, TextInput } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../globalFunctions';
import { storeUnits } from '../../redux/actions/unitAction';

const AddUnitModal = ({ setUnits, setUnitModel, UnitModel }) => {
    const [Name, setName] = useState('')
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);


    const HandleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (Name.trim() == '') {
                return errorMessage('Enter the unit name')
            }
            await dispatch(storeUnits({ name: Name })).then((res) => {

                if (res.status == 'success') {
                    setUnits((Prevalue) => [{ value: res.data.id, label: res.data.name }, ...Prevalue])
                    setName('')
                    setUnitModel(false);
                }
            })
        } catch (error) {

        }
        setLoading(false)
    }

    return (
        <div>
            <Modal opened={UnitModel} onClose={() => setUnitModel(false)} title="Add Unit">
                <form onSubmit={HandleSubmit}>
                    <TextInput value={Name} name='name' onChange={(e) => setName(e.target.value)} label="Unit Name" className='mt-3 required-input' placeholder="Unit Name" requried />
                    <Button
                        className='mt-5'
                        variant="gradient"
                        disabled={isLoading}
                        type='submit'
                        gradient={{ from: 'teal', to: 'green', deg: 172 }}
                    >
                        Add Unit
                    </Button>
                </form>
            </Modal>

        </div>
    )
}

export default AddUnitModal