import { printerConstants } from "../constants/printerConstants";

export const PrinterReducer = (
    state = {
        loading: false,
        error: null,
        PrinterModelsList: [],
        PrinterByBrands: [],
        PrinterByRegion: [],
        showPrinter: [],
    },
    action
) => {
    switch (action.type) {
        case printerConstants.FETCH_PRINTER_MODEL_REQUEST:
        case printerConstants.STORE_PRINTER_MODEL_REQUEST:
        case printerConstants.FETCH_MODEL_BY_BRAND_REQUEST:
        case printerConstants.FETCH_PRINTER_BY_REGION_REQUEST:
        case printerConstants.FETCH_PRINTER_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case printerConstants.FETCH_MODEL_BY_BRAND_SUCCESS:

            return {
                ...state,
                loading: false,
                PrinterByBrands: action.payload,
            };
        case printerConstants.FETCH_PRINTER_BY_ID_SUCCESS:

            return {
                ...state,
                loading: false,
                showPrinter: action.payload,
            };

        case printerConstants.FETCH_PRINTER_BY_REGION_SUCCESS:

            return {
                ...state,
                loading: false,
                PrinterByRegion: action.payload,
            };

        case printerConstants.FETCH_PRINTER_MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
                PrinterModelsList: action.payload,
            };

        case printerConstants.STORE_PRINTER_MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
            };


        case printerConstants.FETCH_PRINTER_BY_REGION_FAILURE:
        case printerConstants.FETCH_MODEL_BY_BRAND_FAILURE:
        case printerConstants.FETCH_PRINTER_BY_ID_FAILURE:
        case printerConstants.FETCH_PRINTER_MODEL_FAILURE:
        case printerConstants.STORE_PRINTER_MODEL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}