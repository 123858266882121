import { counterLogConstants } from "../constants/counterLogsContants";

export const counterLogsReducer = (
    state = {
        loading: false,
        error: null,
        counterLogsList: [],
    },
    action
) => {
    switch (action.type) {
        case counterLogConstants.FETCH_COUNTER_BY_PRINTER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case counterLogConstants.FETCH_COUNTER_BY_PRINTER_SUCCESS:
            return {
                ...state,
                loading: false,
                counterLogsList: action.payload,
            };



        case counterLogConstants.FETCH_COUNTER_BY_PRINTER_FAILURE:

            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}