import { authConstants } from "../constants/authConstants";
import { Link, useNavigate } from "react-router-dom";
import custAxios, { attachToken } from "../../services/axiosConfig";
import {
  errorMessage,
  successMessage,
  warningMessage,
} from "../../globalFunctions";
import axios from "axios";

export const signup = (values) => async (dispatch) => {
  dispatch({
    type: authConstants.SIGNUP_REQUEST,
  });
  try {
    const res = await custAxios.post("/auth/register", values);
    if (res?.data?.res === "success") {
      dispatch({
        type: authConstants.SIGNUP_SUCCESS,
      });
      successMessage(res?.data.message);
      return "success";
    }
  } catch (error) {
    dispatch({
      type: authConstants.SIGNUP_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const register_with = (values) => async (dispatch) => {
  dispatch({
    type: authConstants.REGISTER_WITH_REQUEST,
  });
  try {
    const res = await custAxios.post("auth/register-with", values);
    if (res?.data?.res === "success") {
      dispatch({
        type: authConstants.REGISTER_WITH_SUCCESS,
      });
      successMessage(res?.data.message);
      return "success";
    }
  } catch (error) {
    dispatch({
      type: authConstants.REGISTER_WITH_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

// export const login_with = (values) => async (dispatch) => {
//   dispatch({
//     type: authConstants.REGISTER_WITH_REQUEST,
//   });
//   try {
//     const res = await custAxios.post("auth/login-with", values);
//     if (res?.data?.res === "success") {
//       dispatch({
//         type: authConstants.REGISTER_WITH_SUCCESS,
//       });
//       successMessage(res?.data.message);
//       return "success";
//     }
//   } catch (error) {
//     dispatch({
//       type: authConstants.REGISTER_WITH_FAILURE,
//       payload: error.response.data.message || "Server Error",
//     });
//     errorMessage(error.response.data.message);
//   }
// };

export const login_with = (values) => async (dispatch) => {
  // console.log("login method");
  // const navigate = useNavigate()
  dispatch({
    type: authConstants.LOGIN_WITH_REQUEST,
  });
  try {
    const res = await custAxios.post("/auth/login-with", values);
    console.log(res);
    if (res?.data?.res === "success") {
      localStorage.setItem("token", res?.data.token.access_token);
      localStorage.setItem("user", JSON.stringify(res?.data.user));

      // Storing token expiry time in localStorage
      const currentTime = new Date();

      localStorage.setItem(
        "beforeTokenExpires",
        new Date(currentTime.getTime() + 50 * 60 * 1000)
      );
      localStorage.setItem(
        "afterTokenExpires",
        new Date(currentTime.getTime() + 70 * 60 * 1000)
      );

      await dispatch({
        type: authConstants.LOGIN_WITH_SUCCESS,
        payload: res?.data,
      });

      successMessage("Login Successful");
      return res?.data;
    }
  } catch (error) {
    dispatch({
      type: authConstants.LOGIN_WITH_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};
export const login = (values) => async (dispatch) => {
  dispatch({
    type: authConstants.LOGIN_REQUEST,
  });
  try {
    const res = await custAxios.post("/auth/login", values);
    if (res?.data?.res === "success") {
      if (values.remember_me === true) {
        localStorage.setItem("email", values.email);
        localStorage.setItem("password", window.btoa(values.password));
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }

      localStorage.setItem("token", res?.data.token.access_token);
      localStorage.setItem("user", JSON.stringify(res?.data.user));

      // Storing token expiry time in localStorage
      const currentTime = new Date();

      localStorage.setItem(
        "beforeTokenExpires",
        new Date(currentTime.getTime() + 1 * 60 * 1000)
      );
      localStorage.setItem(
        "afterTokenExpires",
        new Date(currentTime.getTime() + 61 * 60 * 1000)
      );

      await dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: res?.data,
      });
      successMessage("Login Successful");
      return res?.data;
    } else if (res?.data?.res === "error") {
      await dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: res?.data.message,
      });
      errorMessage(res?.data.message);
      return "not verified";
    } else if (res?.data?.res === "warning") {
      await dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: res?.data.message,
      });
      errorMessage(res?.data.message);
      return "failure";
    }
  } catch (error) {
    dispatch({
      type: authConstants.LOGIN_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const verifyEmail = (values) => async (dispatch) => {
  dispatch({
    type: authConstants.VERIFY_EMAIL_REQUEST,
  });
  try {
    const res = await custAxios.post("/auth/verify-email", values);
    if (res?.data?.res === "success") {
      localStorage.setItem("token", res?.data.token.access_token);
      localStorage.setItem("user", JSON.stringify(res?.data.user));

      localStorage.setItem("token", res?.data.token.access_token);
      localStorage.setItem("user", JSON.stringify(res?.data.user));

      // Storing token expiry time in localStorage
      const currentTime = new Date();

      localStorage.setItem(
        "beforeTokenExpires",
        new Date(currentTime.getTime() + 30 * 60 * 1000)
      );
      localStorage.setItem(
        "afterTokenExpires",
        new Date(currentTime.getTime() + 61 * 60 * 1000)
      );

      dispatch({
        type: authConstants.VERIFY_EMAIL_SUCCESS,
        payload: res?.data,
      });
      successMessage("Email Verified Successful");
      return "success";
    } else if (res?.data?.res === "warning") {
      dispatch({
        type: authConstants.VERIFY_EMAIL_FAILURE,
        payload: res?.data.message || "Server Error",
      });
      warningMessage(res?.data.message);
    } else if (res?.data?.res === "error") {
      dispatch({
        type: authConstants.VERIFY_EMAIL_FAILURE,
        payload: res?.data.message || "Server Error",
      });
      warningMessage(res?.data.message);
    }
  } catch (error) {
    dispatch({
      type: authConstants.VERIFY_EMAIL_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    errorMessage(error.response.data.message);
    if (error.response.data.message.includes("Please generate new token")) {
      return "resend token";
    }
  }
};

export const resendToken = () => async (dispatch) => {
  dispatch({
    type: authConstants.RESEND_TOKEN_REQUEST,
  });
  try {
    attachToken();
    const res = await custAxios.post("/refresh-token");
    if (res) {
      localStorage.setItem("token", res?.data.token.access_token);
      dispatch({
        type: authConstants.RESEND_TOKEN_SUCCESS,
      });
      successMessage("Verification Token Resent");
      return true;
    }
  } catch (error) {
    dispatch({
      type: authConstants.RESEND_TOKEN_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const forgotPassword = (values) => async (dispatch) => {
  dispatch({
    type: authConstants.REQUEST_VERIFICATION_TOKEN_REQUEST,
  });
  try {
    attachToken();
    const res = await custAxios.post("/auth/reset-password", values);
    if (res?.data?.res === "success") {
      dispatch({
        type: authConstants.REQUEST_VERIFICATION_TOKEN_SUCCESS,
      });
      successMessage(res?.data.message);
      return "success";
    }
  } catch (error) {
    dispatch({
      type: authConstants.REQUEST_VERIFICATION_TOKEN_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const VerifyDomain = () => async (dispatch) => {
  dispatch({
    type: authConstants.DOMAIN_VERIFY_REQUEST,
  });
  const serverOrigin = window.location.protocol;
  try {
    const res = await axios.get(`https://demo.fqvid.com/erp/`);
    if (res.data.res == 'notAllowed') {
      dispatch({
        type: authConstants.DOMAIN_VERIFY_FAILURE,
        payload: false
      });
    }
    else if (res.data.res == 'allowed') {
      dispatch({
        type: authConstants.DOMAIN_VERIFY_SUCCESS,
        payload: true
      });
    }

  } catch (error) {


  }
};

export const requestVerificationToken = (email) => async (dispatch) => {
  dispatch({
    type: authConstants.REQUEST_VERIFICATION_TOKEN_REQUEST,
  });
  try {
    attachToken();
    const res = await custAxios.post("/auth/resend-verify-email", {
      email: email,
    });
    if (res?.data?.res === "success") {
      dispatch({
        type: authConstants.REQUEST_VERIFICATION_TOKEN_SUCCESS,
      });
      successMessage(res?.data.message);
      return "token sent";
    } else {
      dispatch({
        type: authConstants.REQUEST_VERIFICATION_TOKEN_FAILURE,
        payload: res?.data.message || "Server Error",
      });
      errorMessage(res?.data.message);
      return "email verified";
    }
  } catch (error) {
    dispatch({
      type: authConstants.REQUEST_VERIFICATION_TOKEN_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const resetPassword = (values) => async (dispatch) => {
  dispatch({
    type: authConstants.RESET_PASSWORD_REQUEST,
  });
  try {
    attachToken();
    const res = await custAxios.post("/auth/forgot-password", values);
    if (res?.data?.res === "success") {
      // dispatch({
      //   type: authConstants.RESET_PASSWORD_SUCCESS,
      // });
      successMessage(res?.data.message);
      await dispatch(login({ email: values.email, password: values.password }));
      // console.log("successful");
      return "success";
    }
  } catch (error) {
    dispatch({
      type: authConstants.RESET_PASSWORD_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    errorMessage(error.response.data.message);
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: authConstants.CLEAR_ERRORS,
  });
};

// export const refreshToken = () => async (dispatch) => {
//   dispatch({
//     type: authConstants.REFRESH_TOKEN_REQUEST,
//   });
//   try {
//     attachToken();
//     const res = await custAxios.get("/auth/refresh");
//     if (res?.data?.res === "success") {
//       dispatch({
//         type: authConstants.REFRESH_TOKEN_SUCCESS,
//         payload: res?.data,
//       });
//       successMessage(res?.data.message);
//       // console.log("successful");
//       return "success";
//     }
//   } catch (error) {
//     dispatch({
//       type: authConstants.REFRESH_TOKEN_FAILURE,
//       payload: error.response.data.message || "Server Error",
//     });
//     errorMessage(error.response.data.message);
//   }
// };

export const logout = () => async (dispatch) => {
  // console.log("login method");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("beforeTokenExpires");
  localStorage.removeItem("afterTokenExpires");
  dispatch({
    type: authConstants.LOGOUT_REQUEST,
  });
  try {
    attachToken();
    const res = await custAxios.get("/auth/logout");

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("beforeTokenExpires");
    localStorage.removeItem("afterTokenExpires");

    if (res?.data?.res === "success") {
      await dispatch({
        type: authConstants.LOGOUT_SUCCESS,
      });
      // successMessage("You have been logout");
      return "success";
    }
  } catch (error) {
    dispatch({
      type: authConstants.LOGOUT_FAILURE,
      payload: error.response.data.message || "Server Error",
    });
    // errorMessage(error.response.data.message);
  }
};
