import { Button, FileInput, Select } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Fade, Zoom } from 'react-reveal'
import { storeBulkLocations } from '../../redux/actions/locationAction'
import Papa from 'papaparse';
import { fetchRegion } from '../../redux/actions/regionAction'
import { useSelector } from 'react-redux'
import { errorMessage } from '../../globalFunctions'
import { useNavigate } from 'react-router-dom'

const AddBulkLocationsPage = () => {
    const [file, setFile] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [region, setRegion] = useState('')
    const [csvData, setCsvData] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { regionList } = useSelector((state) => state?.region)
    const [regionData, setRegionsData] = useState([])
    useEffect(() => {
        dispatch(fetchRegion())
    }, [])

    useEffect(() => {
        const names = regionList.map((item) => ({ value: item.id, label: item.name }));
        setRegionsData(names);
    }, [regionList])



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            if (region == null || region == '' || file == null || file == undefined) {
                setIsLoading(false)
                return errorMessage('Please fill all the fields')
            }
            const formData = new FormData()
            formData.append('csv_file', file)
            formData.append('region', region)
            dispatch(storeBulkLocations(formData, 'bulk-store')).then((res) => {
                if (res.status == 'success') {
                    e.target.reset();
                    setFile(null);
                    setRegion(null);
                    setCsvData([])
                }
            })
        } catch (error) {

        }
        setIsLoading(false)
    }
    const handleFileUpload = (e) => {
        const file = e;
        setCsvData([])
        setFile(file)
        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    // 'result' contains the parsed CSV data
                    setCsvData(result.data);
                    // Now you can use 'result.data' in your React component
                },
                header: false, // Set this to true if the first row contains headers
            });
        }
    };


    return (
        <Fade>

            <div className="d-flex mb-3 justify-content-between align-items-end">
                <h3>Add Location</h3>
                <Button
                    className="text-uppercase"
                    variant="gradient"
                    size='sm'
                    gradient={{ from: 'rgba(5, 37, 92, 1)', to: 'rgba(0, 170, 255, 1)', deg: 172 }}
                    onClick={() => navigate('/location/list')}>
                    Locations List
                </Button>
            </div>
            <form onSubmit={handleSubmit}>
                <FileInput
                    variant="filled"
                    onChange={(e) => handleFileUpload(e)}
                    value={file}
                    label="Upload CSV"
                    description="Please upload a CSV file containing only locations, without any additional fields."
                    placeholder="Upload CSV"
                    accept=".csv"
                    withAsterisk
                    className='mt-3'
                />
                <Select
                    onChange={(e) => setRegion(e)}
                    label="Regions"
                    name='regions'
                    value={region}
                    placeholder="Please Select"
                    data={regionData}
                    searchable
                    className='mt-3 w-100  required-input'
                />

                <Button
                    className='mt-5'
                    variant="gradient"
                    type='submit'
                    disabled={isLoading}
                    gradient={{ from: 'teal', to: 'green', deg: 172 }}
                >
                    Add Product
                </Button>
            </form>

            <div className=' csv-data location-csv'>
                {csvData.map((res, i) => (
                    <Zoom>
                        <span className='csv-data' key={i}> <i>{i + 1}.</i> {res}</span>
                    </Zoom>
                ))}

            </div>
        </Fade>
    )
}

export default AddBulkLocationsPage