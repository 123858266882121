
export const technicianConstants = {
    FETCH_TECHNICIAN_REQUEST: "FETCH_TECHNICIAN_REQUEST",
    FETCH_TECHNICIAN_SUCCESS: "FETCH_TECHNICIAN_SUCCESS",
    FETCH_TECHNICIAN_FAILURE: "FETCH_TECHNICIAN_FAILURE",

    STORE_TECHNICIAN_REQUEST: "STORE_TECHNICIAN_REQUEST",
    STORE_TECHNICIAN_SUCCESS: "STORE_TECHNICIAN_SUCCESS",
    STORE_TECHNICIAN_FAILURE: "STORE_TECHNICIAN_FAILURE",

    DESTROY_TECHNICIAN_REQUEST: "DESTROY_TECHNICIAN_REQUEST",
    DESTROY_TECHNICIAN_SUCCESS: "DESTROY_TECHNICIAN_SUCCESS",
    DESTROY_TECHNICIAN_FAILURE: "DESTROY_TECHNICIAN_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
