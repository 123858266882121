import React, { useState } from 'react'
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, TextInput } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { storeProductCategory } from '../../redux/actions/productCategoryAction';
import { errorMessage } from '../../globalFunctions';
const ProductCategoryModal = ({ setCategory, opened, close }) => {
    const [Name, setName] = useState('')
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);


    const HandleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (Name.trim() == '') {
                return errorMessage('Enter the category name')
            }
            await dispatch(storeProductCategory({ name: Name })).then((res) => {

                if (res.status == 'success') {
                    setCategory((Prevalue) => [{ value: res.data.id, label: res.data.name }, ...Prevalue])
                    setName('')
                    close = true
                }
            })
        } catch (error) {

        }
        setLoading(false)
    }

    return (
        <div>
            <Modal opened={opened} onClose={close} title="Add Product Category">
                <form onSubmit={HandleSubmit}>
                    <TextInput value={Name} name='category_name' onChange={(e) => setName(e.target.value)} label="Category Name" className='mt-3 required-input' placeholder="Category Name" requried />
                    <Button
                        className='mt-5'
                        variant="gradient"
                        disabled={isLoading}
                        type='submit'
                        gradient={{ from: 'teal', to: 'green', deg: 172 }}
                    >
                        Add Product Category
                    </Button>
                </form>
            </Modal>

        </div>
    )
}

export default ProductCategoryModal