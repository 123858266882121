
export const brandConstants = {
    FETCH_BRAND_REQUEST: "FETCH_BRAND_REQUEST",
    FETCH_BRAND_SUCCESS: "FETCH_BRAND_SUCCESS",
    FETCH_BRAND_FAILURE: "FETCH_BRAND_FAILURE",

    STORE_BRAND_REQUEST: "STORE_BRAND_REQUEST",
    STORE_BRAND_SUCCESS: "STORE_BRAND_SUCCESS",
    STORE_BRAND_FAILURE: "STORE_BRAND_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
