import { locationConstants } from "../constants/locationConstants";

export const locationReducer = (
    state = {
        loading: false,
        error: null,
        locationList: [],
        locationsByRegion: [],
    },
    action
) => {
    switch (action.type) {
        case locationConstants.FETCH_LOCATION_REQUEST:
        case locationConstants.STORE_LOCATION_REQUEST:
        case locationConstants.DESTROY_LOCATION_REQUEST:
        case locationConstants.FETCH_REGION_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case locationConstants.FETCH_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                locationList: action.payload,
            };

        case locationConstants.FETCH_REGION_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                locationsByRegion: action.payload,
            };
        case locationConstants.STORE_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case locationConstants.DESTROY_LOCATION_SUCCESS:
            const updatedLocationList = state.locationList.filter((item) => item.id !== action.payload);
            const updatedlocationsByRegion = state.locationsByRegion.filter((item) => item.id !== action.payload);
           
            return {
                ...state,
                loading: false,
                locationsByRegion: updatedlocationsByRegion,
                locationList: updatedLocationList,

            };


        case locationConstants.FETCH_LOCATION_FAILURE:
        case locationConstants.STORE_LOCATION_FAILURE:
        case locationConstants.DESTROY_LOCATION_FAILURE:
        case locationConstants.FETCH_REGION_LOCATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}