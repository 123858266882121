import { productConstants } from "../constants/productConstants";

export const productsReducer = (
    state = {
        loading: false,
        error: null,
        products: [],
        searchProducts: [],
        searchLoading: false,
    },
    action
) => {
    switch (action.type) {
        case productConstants.FETCH_PRODUCT_REQUEST:
        case productConstants.STORE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case productConstants.SEARCH_PRODUCT_REQUEST:
            return {
                ...state,
                searchLoading: true,
            };

        case productConstants.FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload,
            };
        case productConstants.STORE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case productConstants.SEARCH_PRODUCT_SUCCESS:
            return {
                ...state,
                searchProducts: action.payload,
                searchLoading: false,
            };


        case productConstants.SEARCH_PRODUCT_FAILURE:
            return {
                ...state,
                searchLoading: false,
                error: action.payload,
            };


        case productConstants.FETCH_PRODUCT_FAILURE:
        case productConstants.STORE_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}