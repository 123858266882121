import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Button, Col, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { LoginFormSchema } from "../validations/ValidationSchema";
import { Checkbox, PasswordInput, TextInput } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login } from "../redux/actions/authActions";
import Fade from "react-reveal/Fade";
const LoginForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);

  const [verifyEmail, setVerifyEmail] = useState(false);

  const initialFormValues = {
    email: localStorage.getItem("email") || "",
    password: localStorage.getItem("password")
      ? window.atob(localStorage.getItem("password"))
      : "",
    remember_me:
      localStorage.getItem("email") && localStorage.getItem("password")
        ? true
        : false,
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, dispatch]);



  const { handleChange, handleBlur, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: initialFormValues,
      validationSchema: LoginFormSchema,
      onSubmit: async (values) => {
        const res = await dispatch(login(values));
        if (res.token) {

          navigate("/")
        }
      }
    });

  return (
    <Fade >
    <div className="loginPage">
      <Form
        className="auth-form "
        id="form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <h2
          style={{ color: "#0b9737" }}
          className="fs-2 text-dark-color font-montserrat fw-bold text-capitalize mt-5 mb-4"
        >
          sign in
        </h2>

        <TextInput
          label="Email"
          type="email"
          id="email"
          name="email"
          placeholder="Enter email"
          className="mb-3"
          error={errors.email && touched.email ? errors.email : null}
          value={values.email.toLowerCase()}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <PasswordInput
          label="Password"
          id="password"
          name="password"
          placeholder="Password"
          className="mb-3"
          error={errors.password && touched.password ? errors.password : null}
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <Col xs={12} className="d-flex justify-content-between">
          <Form.Group className="mb-3">
            <Checkbox
              label="Remember Me"
              id="remember_me"
              name="remember_me"
              checked={values.remember_me}
              onBlur={handleBlur}
              onChange={handleChange}
              className="signup-checkbox remember-me"
            />
          </Form.Group>


        </Col>

        <div className="signinsubmitbtndiv mt-5">
          <Button
            disabled={loading}
            className="signinbtn mb-2 btn-brown-square"
            type="submit"
          >
            <div className="button-content">
              {loading && <Spinner className="me-2" animation="border" />}
              Sign in
            </div>
          </Button>
        </div>

      </Form></div>
    </Fade>
  );
};

export default LoginForm;
