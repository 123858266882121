
export const requesterConstants = {
    FETCH_REQUESTER_REQUEST: "FETCH_REQUESTER_REQUEST",
    FETCH_REQUESTER_SUCCESS: "FETCH_REQUESTER_SUCCESS",
    FETCH_REQUESTER_FAILURE: "FETCH_REQUESTER_FAILURE",

    STORE_REQUESTER_REQUEST: "STORE_REQUESTER_REQUEST",
    STORE_REQUESTER_SUCCESS: "STORE_REQUESTER_SUCCESS",
    STORE_REQUESTER_FAILURE: "STORE_REQUESTER_FAILURE",

    DESTROY_REQUESTER_REQUEST: "DESTROY_REQUESTER_REQUEST",
    DESTROY_REQUESTER_SUCCESS: "DESTROY_REQUESTER_SUCCESS",
    DESTROY_REQUESTER_FAILURE: "DESTROY_REQUESTER_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
