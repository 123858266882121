import { complaintsConstants } from "../constants/complaintsContants";

export const complaintsReducer = (
    state = {
        loading: false,
        error: null,
        complaintsList: [],
    },
    action
) => {
    switch (action.type) {
        case complaintsConstants.FETCH_COMPLAINTS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case complaintsConstants.FETCH_COMPLAINTS_SUCCESS:
            return {
                ...state,
                loading: false,
                complaintsList: action.payload,
            };



        case complaintsConstants.FETCH_COMPLAINTS_FAILURE:

            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}