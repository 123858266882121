import React, { useEffect } from 'react'
import expiredImg from '../../assets/images/license/expired.gif'
import { useDispatch } from 'react-redux';
import { VerifyDomain } from '../../redux/actions/authActions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const LicenseExpiredPage = () => {
    const { verifyLoading, DomainVarify } = useSelector((state) => state?.auth);

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(VerifyDomain())
    }, []);
    const navigate = useNavigate();
    if (DomainVarify) {
        navigate('/');
    }
    return (
        <div className='LicenseExpiredPage '>

            <img className="expiredImg" src={expiredImg} />

            <h2> License Expired!</h2>

        </div>
    )
}

export default LicenseExpiredPage