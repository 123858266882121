import { brandConstants } from "../constants/brandConstants";

export const brandReducer = (
    state = {
        loading: false,
        error: null,
        brandsList: [],
    },
    action
) => {
    switch (action.type) {
        case brandConstants.FETCH_BRAND_REQUEST:
        case brandConstants.STORE_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case brandConstants.FETCH_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,
                brandsList: action.payload,
            };
        case brandConstants.STORE_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,

            };


        case brandConstants.FETCH_BRAND_FAILURE:
        case brandConstants.STORE_BRAND_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}