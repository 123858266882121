import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import LoginPage from "../pages/Auth";
import DashboardLayout from "../pages/DashboardLayout";
import Dashboard from "../pages/admin/Dashboard";
import AdminRoute from "./AdminRoute";
import ProfileSetting from '../pages/AccountSetting'
import NotFound from './NotFound';
import AddProduct from "../pages/products/add-product";
import WebSetting from "../pages/admin/WebSetting";
import ProductListPage from "../pages/products/product-list";
import ModelListPage from "../pages/printers/ModelList";
import AddPrinterPage from "../pages/printers/AddPrinter";
import StoreBulkDepartmentPage from "../pages/departments/BulkDepartment";
import AddBulkLocationsPage from "../pages/locations/AddBulkLocations";
import StoreDepartmentPage from "../pages/departments/AddDepartment";
import AddLocationsPage from "../pages/locations/AddLocation";
import AddBulkCustomer from "../pages/customers/AddBulkCustomer";
import ListCustomerPage from "../pages/customers/listCustomer";
import ListDepartmentPage from "../pages/departments/ListDepartment";
import ListLocationsPage from "../pages/locations/ListLocations";
import PrinterListPage from "../pages/printers/PrinterList";
import QrScannerPage from "../pages/QrCode/QrScanner";
import LicenseExpiredPage from '../pages/license/expired'
import { useSelector } from "react-redux";
import { VerifyDomain } from "../redux/actions/authActions";
import { useDispatch } from "react-redux";
import ViewPrinterPage from "../pages/printers/ViewPrinter";
import AddPurchasePage from "../pages/purchase/AddPurchase";
import AddRequesterPage from "../pages/requester/AddRequester";
import ListRequesterPage from "../pages/requester/ListRequester";
import AddTechnicianPage from "../pages/technicians/AddTechnician";
import ListTechnicianPage from "../pages/technicians/ListTechnician";
import ComplaintsListPage from "../pages/complains/ComplaintsList";
import CommonRoute from "./CommonRoute";
const Router = () => {
  const { verifyLoading, DomainVarify } = useSelector((state) => state?.auth);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(VerifyDomain())
  }, []);

  if (DomainVarify) {
    return (
      <Routes>
        <Route path='/expired' element={<LicenseExpiredPage />} />

        {['/login', '/signin', '/auth', '/auth/login'].map((paths, index) => (

          <Route path={paths} key={index} element={<LoginPage />} />
        ))
        }

        {/* Default Layout routes */}
        <Route path="/" element={<DashboardLayout />}>
          {['/dashboard', '/index', '/', '/admin'].map((pats, i) => (

            <Route index path={pats} key={i} element={<CommonRoute Component={Dashboard} />} />
          ))}
          <Route path='/profile/setting' element={<CommonRoute Component={ProfileSetting} />} />

          <Route path='/product/product-create' element={<CommonRoute Component={AddProduct} />} />
          <Route path='/product/product-list' element={<CommonRoute Component={ProductListPage} />} />

          <Route path='/models/list' element={<CommonRoute Component={ModelListPage} />} />

          <Route path='/printer/create' element={<CommonRoute Component={AddPrinterPage} />} />
          <Route path='/printer/list' element={<CommonRoute Component={PrinterListPage} />} />
          <Route path='/printer/view/:id' element={<CommonRoute Component={ViewPrinterPage} />} />
          <Route path='/printer/scanner' element={<CommonRoute Component={QrScannerPage} />} />

          <Route path='/department/bulk' element={<AdminRoute Component={StoreBulkDepartmentPage} />} />
          <Route path='/department/create' element={<AdminRoute Component={StoreDepartmentPage} />} />
          <Route path='/department/list' element={<AdminRoute Component={ListDepartmentPage} />} />

          <Route path='/location/bulk' element={<AdminRoute Component={AddBulkLocationsPage} />} />
          <Route path='/location/create' element={<AdminRoute Component={AddLocationsPage} />} />
          <Route path='/location/list' element={<AdminRoute Component={ListLocationsPage} />} />

          <Route path='/customer/create' element={<AdminRoute Component={AddBulkCustomer} />} />
          <Route path='/customer/bulk' element={<AdminRoute Component={AddBulkCustomer} />} />
          <Route path='/customer/list' element={<AdminRoute Component={ListCustomerPage} />} />

          <Route path='/purchase/create' element={<CommonRoute Component={AddPurchasePage} />} />

          <Route path='/requester/create' element={<AdminRoute Component={AddRequesterPage} />} />
          <Route path='/requester/list' element={<AdminRoute Component={ListRequesterPage} />} />

          <Route path='/technician/create' element={<AdminRoute Component={AddTechnicianPage} />} />
          <Route path='/technician/list' element={<AdminRoute Component={ListTechnicianPage} />} />

          <Route path='/complaints/list' element={<AdminRoute Component={ComplaintsListPage} />} />

          <Route path='/website-setting' element={<AdminRoute Component={WebSetting} />} />
        </Route>


        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  } else {

    return (
      <Routes>
        <Route path='/' element={<LicenseExpiredPage />} />
        <Route path="*" element={<LicenseExpiredPage />} />
      </Routes>
    );
  }

};

export default Router;
