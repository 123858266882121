import React, { useState } from 'react'
import { Modal, Button, TextInput } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../globalFunctions';
import { storeUnits } from '../../redux/actions/unitAction';
import { storeBrands } from '../../redux/actions/brandsAction';

const AddBrandModal = ({ setBrands, setBrandModel, BrandModel }) => {
    const [Name, setName] = useState('')
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);


    const HandleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (Name.trim() == '') {
                return errorMessage('Enter the brand name')
            }
            await dispatch(storeBrands({ name: Name })).then((res) => {

                if (res.status == 'success') {
                    setBrands((Prevalue) => [{ value: res.data.id, label: res.data.name }, ...Prevalue])
                    setName('')
                    setBrandModel(false);
                }
            })
        } catch (error) {

        }
        setLoading(false)
    }

    return (
        <div>
            <Modal opened={BrandModel} onClose={() => setBrandModel(false)} title="Add Brand">
                <form onSubmit={HandleSubmit}>
                    <TextInput value={Name} name='name' onChange={(e) => setName(e.target.value)} label="Brand Name" className='mt-3 required-input' placeholder="Brand Name" requried />
                    <Button
                        className='mt-5'
                        variant="gradient"
                        disabled={isLoading}
                        type='submit'
                        gradient={{ from: 'teal', to: 'green', deg: 172 }}
                    >
                        Add Brand
                    </Button>
                </form>
            </Modal>

        </div>
    )
}

export default AddBrandModal