
export const productCategoryConstants = {
    FETCH_PRODUCT_CATEGORY_REQUEST: "FETCH_PRODUCT_CATEGORY_REQUEST",
    FETCH_PRODUCT_CATEGORY_SUCCESS: "FETCH_PRODUCT_CATEGORY_SUCCESS",
    FETCH_PRODUCT_CATEGORY_FAILURE: "FETCH_PRODUCT_CATEGORY_FAILURE",

    STORE_PRODUCT_CATEGORY_REQUEST: "STORE_PRODUCT_CATEGORY_REQUEST",
    STORE_PRODUCT_CATEGORY_SUCCESS: "STORE_PRODUCT_CATEGORY_SUCCESS",
    STORE_PRODUCT_CATEGORY_FAILURE: "STORE_PRODUCT_CATEGORY_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
