
export const complaintsConstants = {
    FETCH_COMPLAINTS_REQUEST: "FETCH_COMPLAINTS_REQUEST",
    FETCH_COMPLAINTS_SUCCESS: "FETCH_COMPLAINTS_SUCCESS",
    FETCH_COMPLAINTS_FAILURE: "FETCH_COMPLAINTS_FAILURE",

    STORE_COMPLAINTS_REQUEST: "STORE_COMPLAINTS_REQUEST",
    STORE_COMPLAINTS_SUCCESS: "STORE_COMPLAINTS_SUCCESS",
    STORE_COMPLAINTS_FAILURE: "STORE_COMPLAINTS_FAILURE",

    DESTROY_COMPLAINTS_REQUEST: "DESTROY_COMPLAINTS_REQUEST",
    DESTROY_COMPLAINTS_SUCCESS: "DESTROY_COMPLAINTS_SUCCESS",
    DESTROY_COMPLAINTS_FAILURE: "DESTROY_COMPLAINTS_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
