import { Button, Select, TextInput } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import slugify from 'react-slugify';
import { fetchProductCategory } from '../../redux/actions/productCategoryAction';
import { FaPlus } from 'react-icons/fa';
import { useDisclosure } from '@mantine/hooks';
import ProductCategoryModal from '../../components/modal/ProductCategory';
import { storeProduct } from '../../redux/actions/productAction';
import AddUnitModal from '../../components/modal/AddUnit';
import { fetchUnits } from '../../redux/actions/unitAction';
import Fade from 'react-reveal'
import { fetchBrands } from '../../redux/actions/brandsAction';
import AddBrandModal from '../../components/modal/AddBrand';

const AddProduct = () => {
    const { productCategory } = useSelector((state) => state)
    const { units } = useSelector((state) => state)
    const { brand } = useSelector((state) => state)
    const dispatch = useDispatch();
    const [category, setCategory] = useState([])
    const [brands, setBrands] = useState([])
    const [unitsVal, setUnits] = useState([])
    const [opened, { open, close }] = useDisclosure(false);

    const [UnitModel, setUnitModel] = useState(false);
    const [BrandModel, setBrandModel] = useState(false);

    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const categoryNames = productCategory.productCategory.map((item) => ({ value: item.id, label: item.name }));
        setCategory(categoryNames);


    }, [productCategory]);

    useEffect(() => {
        const UnitNames = units.unitList.map((item) => ({ value: item.id, label: item.name }));
        setUnits(UnitNames);

    }, [units]);
    useEffect(() => {
        const brandNames = brand.brandsList.map((item) => ({ value: item.id, label: item.name }));
        setBrands(brandNames);

    }, [brand]);


    useEffect(() => {

        dispatch(fetchProductCategory());
        dispatch(fetchUnits());
        dispatch(fetchBrands());
    }, [])

    const initialValue = {
        title: '',
        slug: '',
        category: '2',
        unit: '1',
        brand: '1',
        alert_qty: '10',

    }


    const [inputValue, setInputValue] = useState(initialValue)

    const submitProduct = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            dispatch(storeProduct(inputValue)).then((res) => {
                if (res == 'success') {
                    setInputValue(initialValue)
                }
            })
        } catch (error) {

        }
        setLoading(false)
    }

    const handleInput = (value, name) => {
        const updatedInputValue = {
            ...inputValue,
            [name]: value
        };
        if (name === 'title') {
            updatedInputValue.slug = slugify(value);
        }

        setInputValue(updatedInputValue);
    };
    return (
        <Fade>
            <div className='products'>
                <ProductCategoryModal setCategory={setCategory} close={close} opened={opened} />
                <AddUnitModal setUnits={setUnits} UnitModel={UnitModel} setUnitModel={setUnitModel} />
                <AddBrandModal setBrands={setBrands} BrandModel={BrandModel} setBrandModel={setBrandModel} />
                <h3>Add Product</h3>
                <form onSubmit={submitProduct}>
                    <TextInput value={inputValue.title} name='title' onChange={(e) => handleInput(e.target.value, e.target.name)} label="Product Name" className='mt-3 required-input' placeholder="Product Name" requried />
                    <TextInput value={inputValue.slug} label="Product Slug" className='mt-3' readOnly disabled />


                    <div className='d-flex align-items-end w-100 gap-3'>

                        <Select
                            onChange={(e) => handleInput(e, 'category')}
                            label="Category"
                            placeholder="Pick Catetory"
                            name='category'
                            data={category}
                            searchable
                            className='mt-3 w-100 required-input'
                        />
                        <Button
                            type='button'
                            onClick={open}
                            variant="gradient"
                            gradient={{ from: 'lime', to: 'cyan', deg: 233 }}
                        >
                            <FaPlus />
                        </Button>
                    </div>
                    <div className='d-flex align-items-end w-100 gap-3'>
                        <Select
                            onChange={(e) => handleInput(e, 'unit')}

                            label="Unit"
                            name='unit'
                            placeholder="Please Select"
                            data={unitsVal}
                            searchable
                            className='mt-3 w-100  required-input'
                        />
                        <Button
                            type='button'
                            onClick={() => setUnitModel(true)}
                            variant="gradient"
                            gradient={{ from: 'lime', to: 'cyan', deg: 233 }}
                        >
                            <FaPlus />
                        </Button>
                    </div>
                    <div className='d-flex align-items-end w-100 gap-3'>
                        <Select
                            onChange={(e) => handleInput(e, 'brand')}

                            label="Brand"
                            name='brand'
                            placeholder="Please Select"
                            data={brands}
                            searchable
                            className='mt-3 w-100  required-input'
                        />
                        <Button
                            type='button'
                            onClick={() => setBrandModel(true)}
                            variant="gradient"
                            gradient={{ from: 'lime', to: 'cyan', deg: 233 }}
                        >
                            <FaPlus />
                        </Button>
                    </div>
                    <TextInput onChange={(e) => handleInput(e.target.value, e.target.name)} value={inputValue.alert_qty} name='alert_aty' label="Alert Quantity" className='mt-3 required-input' placeholder="Alert Quantity" requried />
                    <Button
                        className='mt-5'
                        variant="gradient"
                        type='submit'
                        disabled={isLoading}
                        gradient={{ from: 'teal', to: 'green', deg: 172 }}
                    >
                        Add Product
                    </Button>
                </form>

            </div>
        </Fade>
    )
}

export default AddProduct