import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { authReducer } from "./reducers/authReducer";
import { formReducer } from "./reducers/formReducer";
import { adminReducer } from "./reducers/adminReducer";
import { userReducer } from "./reducers/userReducer";
import { webReducer } from "./reducers/webReducer";

import { productCategoryReducer } from "./reducers/productCategoryReducer";
import { unitReducer } from "./reducers/unitReducer";
import { productsReducer } from "./reducers/productReducer";
import { brandReducer } from "./reducers/brandReducer";
import { PrinterReducer } from "./reducers/printerReducer";
import { regionReducer } from "./reducers/regionReducer";
import { locationReducer } from "./reducers/locationReducer";
import { departmentReducer } from "./reducers/departmentReducer";
import { customerReducer } from "./reducers/customerReducer";
import { requesterReducer } from "./reducers/requesterReducer";
import { technicianReducer } from "./reducers/technicianReducer";
import { complaintsReducer } from "./reducers/complaintsReducer";
import { counterLogsReducer } from "./reducers/counterLogsReducer";


const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  formFields: formReducer,
  admin: adminReducer,
  web: webReducer,
  productCategory: productCategoryReducer,
  products: productsReducer,
  units: unitReducer,
  brand: brandReducer,
  printers: PrinterReducer,
  region: regionReducer,
  location: locationReducer,
  department: departmentReducer,
  customer: customerReducer,
  requester: requesterReducer,
  technician: technicianReducer,
  complaints: complaintsReducer,
  counterLog: counterLogsReducer,
});

let initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
