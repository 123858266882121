
export const locationConstants = {
    FETCH_LOCATION_REQUEST: "FETCH_LOCATION_REQUEST",
    FETCH_LOCATION_SUCCESS: "FETCH_LOCATION_SUCCESS",
    FETCH_LOCATION_FAILURE: "FETCH_LOCATION_FAILURE",

    STORE_LOCATION_REQUEST: "STORE_LOCATION_REQUEST",
    STORE_LOCATION_SUCCESS: "STORE_LOCATION_SUCCESS",
    STORE_LOCATION_FAILURE: "STORE_LOCATION_FAILURE",


    FETCH_REGION_LOCATION_REQUEST: "FETCH_REGION_LOCATION_REQUEST",
    FETCH_REGION_LOCATION_SUCCESS: "FETCH_REGION_LOCATION_SUCCESS",
    FETCH_REGION_LOCATION_FAILURE: "FETCH_REGION_LOCATION_FAILURE",

    DESTROY_LOCATION_REQUEST: "DESTROY_LOCATION_REQUEST",
    DESTROY_LOCATION_SUCCESS: "DESTROY_LOCATION_SUCCESS",
    DESTROY_LOCATION_FAILURE: "DESTROY_LOCATION_FAILURE",

    CLEAR_ERRORS: "CLEAR_ERRORS",
};
