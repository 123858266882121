
export const departmentConstants = {
    FETCH_DEPARTMENT_REQUEST: "FETCH_DEPARTMENT_REQUEST",
    FETCH_DEPARTMENT_SUCCESS: "FETCH_DEPARTMENT_SUCCESS",
    FETCH_DEPARTMENT_FAILURE: "FETCH_DEPARTMENT_FAILURE",

    STORE_DEPARTMENT_REQUEST: "STORE_DEPARTMENT_REQUEST",
    STORE_DEPARTMENT_SUCCESS: "STORE_DEPARTMENT_SUCCESS",
    STORE_DEPARTMENT_FAILURE: "STORE_DEPARTMENT_FAILURE",

    DESTROY_DEPARTMENT_REQUEST: "DESTROY_DEPARTMENT_REQUEST",
    DESTROY_DEPARTMENT_SUCCESS: "DESTROY_DEPARTMENT_SUCCESS",
    DESTROY_DEPARTMENT_FAILURE: "DESTROY_DEPARTMENT_FAILURE",


    CLEAR_ERRORS: "CLEAR_ERRORS",
};
